import { IHistory } from '../../interfaces';
import * as React from 'react';
import { getUser, getAllUsers } from '../../services/userService';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UserModel } from '../../model/UserModel';
import { BasePage } from '../reusableComponents/BasePage';
import './dashboard.scss';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Loader } from '../reusableComponents/Loader';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { UserQRCode } from '../User/UserQRCode';

export interface IProfileProps {
    history?: IHistory;
    instance: UserModel;
    userId: string;
    userUID: string;
}

export interface IProfileState {
    errorMessage?: string;
    editProfile?: boolean;
}
export class ProfileImpl extends React.Component<IProfileProps, IProfileState> {

    constructor(props: IProfileProps | Readonly<IProfileProps>) {
        super(props);
        this.state = { errorMessage: '', editProfile: false };
    }

    promise = async () => {
        const { instance, userUID } = this.props; 
        if (!instance) {
            await getAllUsers();
        } 
        if(userUID) 
            await getUser(userUID);
        
        return;
    }

    /**
     * Iterated through the given profile values and renders all the profile data accordingly.
     */
    renderProfileRow = (profileKey: {} | null | undefined, value: any, key: React.Key | null | undefined) => {
        if (profileKey === 'Groups' || profileKey === 'Teams') {
            return (
                <Row key={key} className="show-grid">
                    <Col sm={6} className="key">{profileKey}</Col>
                    <Col sm={6} className="value">
                        {(value || []).map((columnValue: React.Key | null | undefined) => {
                            return (
                                <span key={columnValue}>{(columnValue || '')}<br /></span>
                            );
                        })}
                    </Col>
                </Row>
            );
        } else if (profileKey === 'Name') {
            return (
                <Row key={key} className="show-grid">
                    <Col sm={6} className="key">{profileKey}</Col>
                    <Col sm={6} className="value">
                        {value.map((name: any, index: React.Key | null | undefined) => {
                            return (
                                <span key={index}>{(name || '')}&nbsp;</span>
                            );
                        })}
                    </Col>
                </Row>
            );
        }
        return (
            <Row key={key} className="show-grid">
                <Col sm={6} className="key">{profileKey}</Col>
                <Col sm={6} className="value">{value || 'NA'}</Col>
            </Row>
        );
    }

    /**
     * To Render the profile from the instance. Calling render profile row to render the user data with rows.
     */
    renderProfile = () => {
        const { instance } = this.props;
        if (!instance) {
            return <Loader />;
        } 
        const keys = [
            'Name', 'Email', 'Phone Number', 'Access Level', 'Job Title',
            'Job Description', 'Location', 'Teams', 'Groups', 'Company',
        ];

        const { firstName, middleName, lastName, email, userId, accessLevel, signatureUrl,
            jobTitle, jobDesc, locationName, groups, projects, company, phoneNumber, userUID } = instance.props;

        const name = [firstName, middleName, lastName];
        const values = [name, email, phoneNumber, accessLevel, jobTitle, jobDesc, locationName, projects, groups, company];
        return (
            <div className="user-data">
                {keys.map((key, index) => {
                    return this.renderProfileRow(key, values[index], index + 1.);
                })}
                <Row className="show-grid">
                    <Col sm={6} className="key">Signature:</Col>
                    <Col sm={6} className="value"> {signatureUrl ?
                        <img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white'}}
                            src={signatureUrl} alt="SafeConnect"/> : <span style={{ color: 'white' }} >
                            No Signature Saved
                            </span>}</Col>
                </Row>
                <Row className="show-grid">
                    <Col sm={6} className="key">QR Code:</Col>
                    <Col sm={6} className="value"> 
                        <UserQRCode user={`${userUID}/${company}`}/> 
                    </Col>
                </Row>
                <div className="form-button">
                    <Button
                        style={{
                            color: '#FFFFFF',
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                        onClick={() => this.props.history?.push(`/profile/${userId}/edit`)}>
                        Update Profile
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <BasePage>
                <Grid className="profile">
                    <Async
                        identifier="UserProfile"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderProfile()}
                    />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userUID = state.login.get('userUID'); 
    const userInstance = UserModel.get(userId);  
    return {
        instance: userInstance,
        userId, userUID
    };
}

export const Profile = withRouter(connect(mapStateToProps)(ProfileImpl));
