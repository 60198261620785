import { FORM_SUBMISSION_ERROR } from '../constants/general';
import { IHistory, IRules, IFeedback, IForms } from '../interfaces';
import { showAlert } from '../utils/generalUtils';
import { UserModel } from '../model/UserModel';
import { get, post, postCustom, getCustom, delCustom } from '../utils/HTTP';
import { FeedbackModel, IFeedbackModel } from '../model/FeedbackModel';
import { saveWeatherDetails, saveReferenceFiles, saveHomeData } from '../actions/miscellaneousActions'; 
import { getUserInfo } from './loginService'; 
import { setLoading, setSuccess } from '../actions/loadingActions';
import { TeamModel, ITeamModelProps, LocationModel, ILocationModelProps, DesignationModel } from '../model/MiscellaneousModel';
import { isNumber } from 'util';
import {v1 as uuidv1 } from 'uuid';
/* declare var require: any;
const uuidv1 = require('uuid/v1'); */


export async function getHomeData() { 
    try {   
        const company = UserModel.getCompanyName();
        const { data: { data } } = await getCustom(`/GetHomeData/${company}/${UserModel.getAccessLevel()}`); 
        saveHomeData(data[0] || '', data[1] || '', data[2] || '' );
    } catch (error) {
        throw error;
    }
}

export async function getGoldenRules(companyName: string = 'spi') {
    try {
        const response = await get(`/GoldenRules`); 
        let rulesArray: IRules[] = [];
        response.data.data.map((rules: IRules) => {
            rulesArray.push(rules);
        });
        return rulesArray;
    } catch (error) {
        throw error;
    }
}

export async function submitGoldenRules(datas: any, history?: IHistory) {
    let rulesArray: { rule: any; priority: number | null; ruleNum: any; }[] = []; 
    let hasError: boolean = false;
    datas['rule'].forEach((el: any, i: number) => {  
        let priority = datas['priority'][i] ? +datas['priority'][i]: null; 
        if(priority && ( !isNumber(priority) || priority<0 ) ) {
            hasError = true; 
        }
        rulesArray.push({ rule: el, priority: +datas['priority'][i] || null, ruleNum: i + 1 }); 
    }); 
    if(hasError) {
        showAlert('Priorities should be in positive numer', 'golden-rules-failed', 'danger');
        return {response:null, updatedRules:null};
    }
    /* datas.map((data, index) => rulesArray.push({ rule: data['rule'], priority: data['priority'], ruleNum: index + 1 })); */
     
    const requestBody = { rules: rulesArray };
    try { 
        const response = await post('/GoldenRules', requestBody);
        const updatedRules = await getGoldenRules();
        showAlert(response.data.message, 'golden-rules-success', 'success-alert');
        return { response, updatedRules };
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'golden-rules-failed', 'danger');
        throw error;
    }
}

export async function submitFeedback(feedbackData: IFeedback, history: IHistory) {
    try {
        const response = await postCustom('/Feedback', feedbackData);
        if (JSON.stringify(response.data.toString()).toLowerCase().indexOf('error') > -1) {
            showAlert('Cannot submit feedback please try again later', 'feedback-failed', 'danger');
            return;
        }
        showAlert(response.data.message, 'feedback-success', 'success', () => {
            history.push('/home');
        });
        return response;
    } catch (error) {
        showAlert('Cannot submit feedback please try again later', 'feedback-failed', 'danger');
        throw error;
    }
}

export async function getAllFeedbacks() {
    try {
        const company = UserModel.getCompanyName();
        const response = await getCustom(`/Feedback/${company}`);
        (response.data.data || []).forEach((feedback: IFeedbackModel) => {
            feedback.id = feedback.uniqueId;
            new FeedbackModel(feedback).$save();
        });
        return response.data;

    } catch (error) {
        throw JSON.stringify(error);
    }
}

export async function getWeatherDetails(lat: number, long: number) {
    try {
        // tslint:disable-next-line
        const response = await getCustom(`https://api.openweathermap.org/data/2.5/weather?lat=32.997821&lon=-97.066542&APPID=576e076b850f51b8faee733725a92198`);
        const weatherDetails = {
            name: '', latitude: 0, longitude: 0, description: '', temp: 0, pressure: '', humidity: '',
            temp_min: 0, temp_max: 0, sea_level: '', grnd_level: '', country: '', sunrise: '', sunset: '',
            icon: ''
        };

        weatherDetails.name = response.data.name;
        weatherDetails.latitude = lat;
        weatherDetails.longitude = long;
        weatherDetails.description = response.data.weather[0].description;
        weatherDetails.pressure = response.data.main.pressure;
        weatherDetails.humidity = response.data.main.humidity;
        weatherDetails.temp = Math.round((response.data.main.temp - 273.15));
        weatherDetails.temp_min = Math.round((response.data.main.temp_min - 273.15));
        weatherDetails.temp_max = Math.round((response.data.main.temp_max - 273.15));
        weatherDetails.sea_level = response.data.main.sea_level;
        weatherDetails.grnd_level = response.data.main.grnd_level;
        weatherDetails.sunrise = response.data.sys.sunrise;
        weatherDetails.sunset = response.data.sys.sunset;
        weatherDetails.sunset = response.data.sys.sunset;
        weatherDetails.country = response.data.sys.country;
        weatherDetails.icon = response.data.weather[0].icon;
        saveWeatherDetails(weatherDetails);
    } catch (error) {
        throw error;
    }
}
export async function saveReferences(refTitle: string, URL: string, file: any, bannerURL: string | any[], history: IHistory) {
    let data = {
        company: '',
        referenceTitle: '',
        referenceLink: '',
        referenceFileData: '',
        bannerImage: ''
    };
    try {
    /* 
        const uuid = uuidv1();  
        let bannerImage = ''; 
        if(bannerURL.length > 0) {
            const file = await base64ToFile(bannerURL[0].image, bannerURL[0].name)
                .then(file => {
                    return file;
                });
            await uploadFile(file, bannerURL[0].name.split('.')[0], 'reference-files/banners');
            bannerImage = `${S3_URL}/reference-files/banners/${bannerURL[0].name}`; 
        }
        if (file.length > 0) {
            await uploadReferenceFile(file[0], uuid, 'reference-files');
            data = {
                company: getUserInfo().company,
                referenceTitle: refTitle,
                referenceLink: `${S3_URL}/reference-files/${uuid}`,
                bannerImage: bannerImage
            };
        } else {
            data = {
                company: getUserInfo().company,
                referenceTitle: refTitle,
                referenceLink: URL,
                bannerImage: bannerImage
            };
        } */
        /* if (file.length > 0) {
            const fileReader = new FileReader();
            fileReader.onload = this.readFile; 
            fileReader.readAsDataURL(file[0]); 
        } */ 
        data = {
            company: getUserInfo().company,
            referenceTitle: refTitle,
            referenceLink: URL,
            referenceFileData: !URL? file: null,
            bannerImage: (bannerURL && bannerURL[0]) || null
        };  
        await post('/References', data);
        showAlert('File/URL Uploaded Successfully..!!', 'reference-upload-success', 'success', () => {
            history.push('/reference/show');
        });
    } catch (error) {
        throw error;
    }
} 

export async function getAllReferences(companyName?: string) {
    try {
        const response = await get(`/References`);
        const id1 = uuidv1();
        const id2 = uuidv1(); 
        const ref1 = {
            referenceId: id1,
            isDeletable: true,
            referenceTitle: 'SafeConnect Description',
            referenceLink: 'https://www.youtube.com/watch?reload=9&v=PQOPLkkL4OA&t=9s'
        };
        const ref2 = {
            referenceId: id2,
            isDeletable: true,
            referenceTitle: 'SafeConnect User Instructions',
            referenceLink: 'https://www.youtube.com/watch?v=0krjcRCmhwg&t=4s'
        };
        const data = response.data.data || [];
        (data || []).push(ref1);
        (data || []).push(ref2);
        saveReferenceFiles(data || []);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function deleteReferences(referenceIDs: any[]) {
    if (!(referenceIDs instanceof Array)) {
        referenceIDs = [referenceIDs];
    }
    try {
        const company = UserModel.getCompanyName();
        const response = await delCustom(`/References`, { referenceIds: referenceIDs, company: company });
        showAlert('File Deleted Successfully..!!', 'reference-upload-success', 'success', () => { });
        setLoading('ReferencePage');
        await getAllReferences();
        setSuccess('ReferencePage');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getCompanyTeams() {
    try {
        UserModel.getCompanyName();
        const {data} = await get('/GetCompanyTeams'); 
        (data.teams || []).forEach((teamInstance: ITeamModelProps) => {
            let instance = marshallTeamInstance(teamInstance);
            new TeamModel(instance).$save();
        });
        return true;
    } catch (error) {
        throw error;
    }
}
export function marshallTeamInstance(data: ITeamModelProps) {  
    data.id = data.team; 
    return data; 
}

export async function getExportData(filterParam: any) { 
    try { 
        //const data = { formType, }; 
        const response = await post('/GetExportData', filterParam); 
        return response;
    } catch (error) {
        throw error;
    }
}

export async function submitLocationForm(answers: any, history: IHistory,  forms: IForms ) { 
    try {
        const response = await post('/Location', answers);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'location-form-form', 'danger');
            return;
        } 
        showAlert(response.data.message, 'location-modal', 'success', async () => {
            history.push('/home');  
        });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'location-form-failed', 'danger');
        throw error;
    } 
}

export async function getAllLocations() {
    try {
        const company = UserModel.getCompanyName();
        let response = await getCustom(`/Location/${company}`); 
        if (response.data.status === 'valid') {
            LocationModel.deleteAll();
            response
                .data
                .data
                .forEach((formData: any) => {  
                    formData.company = company;
                    new LocationModel(formData).$save();
                }); 
        }
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'location-form-failed', 'danger');
        throw error;
    } 
}

export async function submitDesignationForm(answers: any, history: IHistory,  forms: IForms ) { 
    try {
        const response = await post('/Designation', answers);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'designation-form-form', 'danger');
            return;
        } 
        showAlert(response.data.message, 'designation-modal', 'success', async () => {
            history.push('/home');  
        });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'designation-form-failed', 'danger');
        throw error;
    } 
}
export async function getAllDesignations() {
    try {
        const company = UserModel.getCompanyName();
        let response = await getCustom(`/Designations/${company}`); 
        if (response.data.status === 'valid') {
            DesignationModel.deleteAll();
            response
                .data
                .data
                .forEach((formData: any) => {  
                    formData.company = company;
                    new DesignationModel(formData).$save();
                }); 
        }
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'designation-form-failed', 'danger');
        throw error;
    } 
}

export async function submitConcernTypesForm(answers: any, history: IHistory,  forms: IForms ) { 
    try {
        const response = await post('/ConcernTypes', answers);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'concern-type-form', 'danger');
            return;
        } 
        showAlert(response.data.message, 'concern-type-modal', 'success', async () => {
            history.push('/home');  
        });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'concern-type-form-failed', 'danger');
        throw error;
    } 
}