import { fromJS } from 'immutable';

import {
    HIDE_IMAGE,
    HIDE_SUB_DROPDOWN_MENU,
    HIDE_WEATHER_MODAL,
    IS_IMAGE_LOADING,
    RESET_FIELD_EMPTY,
    SAVE_CUSTOM_NOTIFICATION,
    SAVE_FILTER_DATA,
    SAVE_SUB_DROPDOWN_VALUE,
    SAVE_WEATHER_DETAILS,
    SET_FIELD_EMPTY,
    SHOW_IMAGE,
    SHOW_SUB_DROPDOWN_MENU,
    SHOW_WEATHER_MODAL,
    SAVE_REFERENCE_ITEMS, 
    SAVE_LIST_COUNT, SAVE_HOME_COUNTS, SAVE_AXIOS_PREVIOUS_TOKEN
} from '../constants/actions';

export function miscellaneousReducer(state: any = fromJS({}), action: any) {
    switch (action.type) {
        case SHOW_IMAGE:
            return state.set('imageURL', action.imageURL);
        case HIDE_IMAGE:
            return state.set('imageURL', '');
        case IS_IMAGE_LOADING:
            return state.set('isImageLoading', action.isLoading);
        case SET_FIELD_EMPTY:
            //return state.set('fieldModelName', '.' + action.fieldModelName);
            return state.set('fieldModelName', action.fieldModelName);
        case RESET_FIELD_EMPTY:
            return state.set('fieldModelName', '');
        case SAVE_CUSTOM_NOTIFICATION:
            return state.set('customNotitification', action.customNotification);
        case SHOW_SUB_DROPDOWN_MENU:
            return state.set('showSubMenuOf', action.key);
        case HIDE_SUB_DROPDOWN_MENU:
            return state.delete('showSubMenuOf');
        case SAVE_SUB_DROPDOWN_VALUE:
            return state.set('subDropdownValue', action.value);
        case SAVE_FILTER_DATA:
            return state.set('filterOIReports', {
                year: action.yyyy || 'na',
                filterType: action.filterType || 'summary',
                month: action.mm || 'na'
            });
        case SAVE_WEATHER_DETAILS:
            return state.set('weatherDetails', action.weatherDetails);
        case SHOW_WEATHER_MODAL:
            return state.set('showWeatherModal', true);
        case HIDE_WEATHER_MODAL:
            return state.set('showWeatherModal', false);
        case SAVE_REFERENCE_ITEMS:
            return state.set('referenceFiles', action.referenceFiles);
        /* case SAVE_OI_FORM_COUNT:
            return state.set('oiFormCount', action.totalCount); */
        /* case SAVE_OFFICE_SAFETY_FORM_COUNT:
            return state.set('officeSafetyFormCount', action.totalCount); */
        case SAVE_LIST_COUNT:
            return state.set('listCount', action.totalCount);
        case SAVE_HOME_COUNTS: 
            return state.set('totalGroups', action.groupCount).set('totalProjects', action.projectCount).set('totalUsers', action.userCount);
        case SAVE_AXIOS_PREVIOUS_TOKEN:
            return state.set('axiosPreviousToken', action.token);
        default:
            return state;
    }
}
