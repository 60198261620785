import * as React from 'react';
import PropTypes from 'prop-types';
import { Control, actions } from 'react-redux-form';
import { dispatch } from '../../../utils/generalUtils';
import { ISelectOptions, IForms } from '../../../interfaces';
import { connect } from 'react-redux';
import './rrfInput.scss';
import { resetEmptyField, setEmptyField } from '../../../actions/miscellaneousActions';
import { format } from 'date-fns';
import { withRouter, RouteProps } from 'react-router';
//import DatePicker from 'react-date-picker/dist/DatePicker'; 
import DatePicker from 'react-date-picker';
//import Select from "react-dropdown-select";  
import Select from 'react-select'; 
//import makeAnimated from 'react-select/animated';
//import { optionCSS } from 'react-select/src/components/Option';
//const animatedComponents = makeAnimated();

export interface IRRFInputProps {
    id?: string;
    type?: string;
    textAreaRows?: number;
    placeholder?: string;
    radioValue?: string;
    radioName?: string;
    model?: string;
    defaultValue?: any;
    multi?: boolean;
    searchable?: boolean;
    isDisabled?: boolean;
    onChange?: (value: boolean | any, model?: string) => void;
    onSelect?: (value: string) => void;
    onBlur?: (value: string) => void;
    menuItems?: ISelectOptions[];
    className?: string;
    forms?: IForms;
    optionComponent?: JSX.Element;
    value?: string;
    valueComponent?: JSX.Element;
    fieldModelName?: string;
    onKeyPress?: (e: any) => void;
    onKeyDown?: (e: any) => void;
    noResultsText?: string;
    isChecked?: boolean; 
    mindate?: string;
    maxdate?: string;
    maxChar?: number;
    minChar?: number;
    readOnly?: boolean;
}

export interface IRRFInputState {
    date: Date | undefined;
    inputValue: string;
    RDropdownDefaultValue?: any;
}

export class RRFInputImpl extends React.Component<IRRFInputProps & RouteProps, IRRFInputState> { 
    constructor(props: IRRFInputProps | Readonly<IRRFInputProps>) {
        super(props);  
        //if(props.type =='date') 
            /* var offset = new Date().getTimezoneOffset(); */ 
        this.state = {
            inputValue: props.value || '',
            date: props.defaultValue===undefined || props.defaultValue==='undefined' || props.defaultValue==='null' || props.defaultValue==='null' || props.defaultValue===null || props.defaultValue===null || props.defaultValue==='0000-00-00' || props.defaultValue==='0001-11-30'
            ? undefined : ( props.defaultValue ? new Date(props.defaultValue + 'T17:00:00.00Z') : new Date() )
        };
        //console.log(props.model +' -> '+ props.defaultValue+' -> '+ typeof(props.defaultValue) +' -> '+ this.state.date)

    }

    static contextTypes = {
        formModel: PropTypes.string
    };

    static defaultProps = {
        className: ''
    };

    componentDidMount() {
        const { props: { defaultValue, model}, context: { formModel } } = this; 
        if (defaultValue) {
            dispatch(actions.change(`${formModel}${model}`, defaultValue));
            this.setState({RDropdownDefaultValue: defaultValue})
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { props: { model }, context: { formModel } } = this;
        const { defaultValue: nextDefaultValue } = nextProps; 
        if (nextDefaultValue !== this.props.defaultValue && formModel!=='forms.permitToWorkForm') { 
            dispatch(actions.change(`${formModel}${model}`, nextDefaultValue)); 
            this.setState({RDropdownDefaultValue: nextDefaultValue})
            this.setState({
                date: new Date(nextDefaultValue + 'T17:00:00.00Z')
            });
            return;
        }
    }

    getComponent = () => {
        const { type } = this.props;
        if (type === 'text') {
            return Control.text;
        } else if (type === 'textarea') {
            return Control.textarea;
        } else if (type === 'radio') {
            return Control.radio;
        } else if (type === 'checkbox') {
            return Control.checkbox;
        } else if (type === 'dropdown') {
            return Select;
        } else if (type === 'date') {
        }
        return Control;
    }
   
    getProps = () => {
        const { props: {
            id, fieldModelName, onKeyPress, isDisabled, type, placeholder, model, className, mindate, maxdate, textAreaRows, readOnly  }, context } = this;
        let props = {
            onBlur: (this.handleBlurChange),
            onKeyDown: (e: any) => (onKeyPress ? onKeyPress(e) : null),
            onKeyPress: (e: any) => (onKeyPress ? onKeyPress(e) : null),
            //onKeyPress: (e: any) => (minChar || maxChar ? this.onKeyPressChange(e) : null),
            id, disabled: (isDisabled ? isDisabled : false), readOnly: (readOnly ? readOnly : false), type, placeholder, mindate:(mindate?mindate:''),maxdate:(maxdate?maxdate:''),
            model: (`${context.formModel}${model}`), onChange: (this.handleInputChange),
            className: (`form-control ${className} ${(Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || (fieldModelName === model) ? 'empty-field' : ''}`),
            /* minLength: minChar || '', maxLength: maxChar || '',  */
            rows: textAreaRows
        };
        /* if(type=='number') {
            let numProps = {maxLength: 2}
            props = {...props, ...numProps};
        } */
        return props;
    } 
    
    getRadioProps = () => {
        const { id, isDisabled, className, fieldModelName, model, type, radioName, radioValue, isChecked } = this.props;
        const { formModel } = this.context; 
        const props = {
            id, disabled: (isDisabled ? isDisabled : false), checked: isChecked || false,
            className: (`radio-input ${className} ${(Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || (fieldModelName === model)  ? 'empty-field' : ''}`),
            value: radioValue, type, model: (`${formModel}${model}`), name: radioName, onChange: this.handleRadioClick,
        };
        return props;
    } 
    getCheckboxProps = () => {
        const { id, type, isDisabled, model, fieldModelName } = this.props;
        const { formModel } = this.context;
        const props = {
            type,
            disabled: (isDisabled ? isDisabled : false),
            id,
            onChange: this.handleCheckBoxChange,
            model: (`${formModel}${model}`),
            className: ((Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || (fieldModelName === model)  ? 'empty-field' : '')
        };
        return props;
    } 
    handleCheckBoxChange = async (event: any) => {
        const { model, onChange } = this.props; 
        const { formModel } = this.context; 
        //console.log(event.target.checked)
        let value = event.target.checked?1:null
        await dispatch(actions.change(`${formModel}${model}`, value));  
        if (onChange && model) {
            return onChange(event.target.checked, model.split('.')[1]);
        }
    }
    getDropdownProps = () => {
        let { props: { id, fieldModelName, defaultValue, multi, searchable, forms,
            isDisabled, menuItems, placeholder, model, className, optionComponent,
            valueComponent, noResultsText }, context: { formModel } } = this;
        const formName = formModel.split('.');  
        //console.log(model, defaultValue, menuItems, model)
        if(model) {
            const modelName = model.split('.'); 
            let dropDownValue = (forms as any)[`${formName[1]}`][`${modelName[1]}`]; 
           /*  if (modelName.length === 3) { 
                console.log(modelName, dropDownValue)
            } */
            if ( modelName[1].indexOf("[") !== -1) { 
                const index = modelName[1].substring(modelName[1].length - 2, modelName[1].length - 1);  
                dropDownValue = modelName.length === 3? ( (forms as any)[`${formName[1]}`][`${modelName[1].split('[')[0]}`][index][`${modelName[2]}`] || '') : ((forms as any)[`${formName[1]}`][`${modelName[1].split('[')[0]}`][index] || '') ; 
                //dropDownValue = (forms as any)[`${formName[1]}`][`${modelName[1].split('[')[0]}`][index]; 
            }   
            let defaultValueIndex: string | number | any[] = [] || '';   
            if(dropDownValue && typeof dropDownValue ==='string' && dropDownValue.indexOf(',')>-1) { 
                dropDownValue = dropDownValue?.toLowerCase().split(',')
            }
            if(menuItems) { 
                for (var i=0; i<menuItems.length; i++) { 
                   let menuItemValue = menuItems[i].value && (typeof(menuItems[i].value)==='string'?menuItems[i].value?.toLowerCase():menuItems[i].value);
                   let dropdownValue = ( dropDownValue && ( typeof(dropDownValue)==='string'? dropDownValue?.toLowerCase() : dropDownValue) ) || ''; 
                    //if( (Array.isArray(dropDownValue) && dropDownValue.indexOf(menuItems[i].value?.toLowerCase())>-1 ) || ( typeof dropDownValue === 'string' && menuItems[i].value?.toLowerCase()===dropDownValue?.toLowerCase()) ){ 
                    //console.log(menuItemValue, dropdownValue)
                    if( ( (Array.isArray(dropDownValue) && dropDownValue.indexOf(menuItemValue)>-1 ) ) || ( ( typeof dropDownValue === 'string' && menuItemValue===dropdownValue) ) ){
                        defaultValueIndex.push(menuItems[i]); 
                    } 
                } 
            } else { 
                menuItems = [];
            }  
            //console.log(fieldModelName, Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0, model)
            const props = {
                id, clearable: false, isMulti: (multi ? multi : false), simpleValue: true,
                searchable: (searchable ? searchable : false), isDisabled: (isDisabled ? isDisabled : false),
                value: defaultValueIndex, placeholder: (placeholder ? placeholder : 'Select...'), optionComponent, valueComponent, noResultsText, defaultValue,
                options: menuItems, onChange: this.handleDropdownChange, className: (`select-dropdown ${className} ${(Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || (fieldModelName === model)  ? 'empty-field' : ''}`)
            };
            return props;
        }
    } 
    handleDropdownChange = async (data: any) => { 
        const { props: { model }, context: { formModel } } = this;
        let selectedValue = '';
        if(data.length) {
            await dispatch(actions.change(`${formModel}${model}`, selectedValue))
            for (var i=0; i<data.length; i++) { 
                selectedValue = selectedValue + data[i].value + ','; 
            }
            selectedValue = selectedValue.slice(0, -1); 
            this.passToDropdownModel(selectedValue);
        } else {
            selectedValue = data.value; 
            this.passToDropdownModel(selectedValue);
        }
    }
    passToDropdownModel = async (selectedValue: string) => {
        const { props: { fieldModelName, model, onSelect }, context: { formModel } } = this;
        if (fieldModelName) {
            //resetEmptyField();
            this.removeEmptyFieldIndication(model)
        } 
        await dispatch(actions.change(`${formModel}${model}`, selectedValue));
        if (onSelect) {
            return await onSelect(selectedValue);
        }
    }
    handleSelect = (selectedValue: any) => { 
        selectedValue = selectedValue.value;
        const { props: { fieldModelName, model, onSelect }, context: { formModel } } = this; 
        if (fieldModelName) {
            //resetEmptyField(); 
            this.removeEmptyFieldIndication(model)
        }
        dispatch(actions.change(`${formModel}${model}`, selectedValue));
        if (onSelect) {
            return onSelect(selectedValue);
        }
    } 
    onDateChange = (value: Date) => {
        const { props: { model, onChange }, context: { formModel } } = this;
        const date = format(value, 'yyyy-MM-dd'); 
        this.setState({
            date: value
        });
        //resetEmptyField();  
        this.removeEmptyFieldIndication(model)
        dispatch(actions.change(`${formModel}${model}`, date));
        if (onChange) {
            return onChange(date);
        }
    } 
    handleInputChange = async (event: any) => {
        const { props: { fieldModelName, onChange, model }, context: { formModel } } = this;  
        if (fieldModelName) {
            //resetEmptyField(); 
            this.removeEmptyFieldIndication(model)
        }
        /* let value = event.target.value; */ 
        /* if(maxChar) { 
            if(type=='number') {
                if(parseFloat(value)>maxChar) {  console.log(parseFloat(value), maxChar, type, parseFloat(value)>maxChar, `${formModel}${model}` )
                    await dispatch(actions.change(`${formModel}${model}`, 0));
                    return;
                }
            }
        }  */
        await dispatch(actions.change(`${formModel}${model}`, event.target.value));
        if (onChange) {
            await onChange(event, `${formModel}${model}`);
        }
        await this.setState({
            inputValue: event.target.value
        });
    }
    onKeyPressChange = (e: any) => { 
        /* const { props: {
            id, fieldModelName, type, model, maxChar, minChar, forms }, context,
            context: { formModel } } = this;  */
        /* if(maxChar && minChar) {
            let res = e.key.match(/[A-E]/i); 
            if(!res) {
                dispatch(actions.change(`${formModel}${model}`, ''));  
                e.preventDefault();
                return false;
            }
        } */ 
    }
    handleBlurChange = (event: any) => {
        const { props: { onBlur } } = this;
        if (onBlur) {
            return onBlur(event);
        }
    }
    handleRadioClick = async (event: any) => { 
        const { model, onChange } = this.props; 
        const { formModel } = this.context;  
        let value = event.target.value;
        await dispatch(actions.change(`${formModel}${model}`, value));  
        if (onChange) { 
            return onChange(event.target.value);
        }
        return event.target.value;
    } 
    renderDropdownOptions(){
        const {menuItems} = this.props;
        let options: any[] = []
        if(menuItems) {
            for (var i=0; i<menuItems.length; i++) {  
                options.push( <option key={menuItems[i]['value']} value={menuItems[i]['value']}>{menuItems[i]['label']}</option>)
            }
        } 
        return options;
    } 
    renderInput = () => {
        const { type, fieldModelName, model } = this.props; 
        /* const Component = this.getComponent(); */ 
        if (type === 'checkbox') {
            return <label className="switch">
                <Control.checkbox {...this.getCheckboxProps()} />
                <span className="slider round"/>
            </label>;
        }
        if (type === 'radio') {
            return <Control.radio {...this.getRadioProps()} />;

        }
        if (type === 'dropdown') { 
            return <Select {...this.getDropdownProps()} />
        }

        if (type === 'date') {  
            let defaultDate = this.state.date?.toString()!=='Invalid Date'?this.state.date:null;
            return <DatePicker
                className={`date-picker ${ (Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || ((Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) || (fieldModelName === model) ) ? 'empty-field' : ''} ${this.props.isDisabled?'field-disabled':''}` }
                onChange={this.onDateChange}
                //value={this.state.date}
                value={defaultDate}
                locale="en-US"
                minDate={this.props.mindate?new Date(this.props.mindate + 'T17:00:00.00Z'):undefined } 
                maxDate={this.props.maxdate?new Date(this.props.maxdate + 'T17:00:00.00Z'):undefined }
                disabled={this.props.isDisabled}
                clearIcon={null}
                calendarClassName="date-picker-calender"
            />;
        }
        if (type === 'datetime') {
             
        }
        if (type === 'textarea') {
            return (<Control.textarea {...this.getProps()} />);
        }
        return (<Control.text {...this.getProps()} />);
    }
    removeEmptyFieldIndication(model) {
        let { fieldModelName } = this.props;
        if(Array.isArray(fieldModelName) && fieldModelName.indexOf(model) >= 0) {
            //fieldModelName.splice(model, 1);
            let fields = fieldModelName.filter(val => val !== model);
            console.log(fieldModelName, model, fields)
            setEmptyField(fields);
        } 
    }
    render() { 
        return (<>{this.renderInput()}</>);
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms,
        fieldModelName: state.miscellaneous.get('fieldModelName')
    };
} 

export const RRFInput = withRouter(connect<IRRFInputProps, any, any>(mapStateToProps)(RRFInputImpl));
