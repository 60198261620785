import * as React from 'react';
import { format, differenceInCalendarDays } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserModel } from '../../model/UserModel';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Label } from '../reusableComponents/FormComponents/Label';
import { Loader } from '../reusableComponents/Loader';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { getProjectsOfUser } from '../../services/userService';
import { FileInput } from '../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { getAllIncidentNotificationFormsOptions } from '../../services/incidentNotificationFormService';
import { SelectOptionComponent } from '../reusableComponents/ReactSelectComponents/SelectOptionComponent';
import { dispatch } from '../../utils/generalUtils';
import { actions } from 'react-redux-form';
import { ValueComponent } from '../reusableComponents/ReactSelectComponents/ValueComponent';
import { IForms, IHistory } from '../../interfaces';
import { ProjectModel } from '../../model/ProjectModel';
import { ConcernDropdown } from '../reusableComponents/ConcernTypesComponents/ConcernDropdown';
import { SecurityForm } from './SecurityForm'; 
import { IncidentReportModel } from '../../model/IncidentReportModel';  
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IGeneralInformationFormProps {
    userId: string;
    forms: IForms;
    associatedFormId: string;
    populateValues: (formId: string) => void;
    incidentNotificationInstances?: IncidentNotificationModel[];
    userInstance?: UserModel; 
    history?: IHistory;
    editInstance?: IncidentReportModel;
    incidentNotificationInstance: IncidentNotificationModel;
} 

export interface IGeneralInformationFormState {
    currentDate: string;
    currentTime: string;
    selectValue: string;
    isConcernTypeSecurity: boolean;
    dateReportedMinDate: string;
    dateReportedMaxDate: string;
    dateOfIncidentMinDate: string;
}

export class GeneralInformationFormImpl extends React.PureComponent<IGeneralInformationFormProps,
    IGeneralInformationFormState> {
    constructor(props: IGeneralInformationFormProps | Readonly<IGeneralInformationFormProps>) {
        super(props);
        if (props.associatedFormId) {
            dispatch(actions.change(`forms.incidentDetails.associatedFormId`, props.associatedFormId));
        }
        this.state = { currentDate: format(new Date(), 'yyyy-MM-dd'), currentTime: format(new Date(), 'HH:mm'),
            selectValue: props.associatedFormId || '', isConcernTypeSecurity: false, dateReportedMinDate: '', dateReportedMaxDate: '', dateOfIncidentMinDate: ''
        };
    }

    componentDidMount() {
        const { props: { associatedFormId } } = this;
        if (associatedFormId) {
            dispatch(actions.change(`forms.incidentDetails.associatedFormId`, this.props.associatedFormId));
            this.props.populateValues(associatedFormId);
        }
    }

    handleAssociatedFormSelect = (formId: string) => { 
        //const { incidentNotificationInstance } = this.props;
        //const incidentNotificationInstance = IncidentNotificationModel.get(formId);  
        this.setState({dateReportedMinDate: ''}); 
        if(formId==='No Report') {
            this.setState({dateReportedMinDate: this.state.currentDate}); 
        }
        this.props.populateValues(formId);  
    }

    promise = async () => {
       /*  if (IncidentNotificationModel.list().length <= 0) {
            await getAllIncidentNotificationForms();
        }
        return null; */
    }

    getProjects = () => {
        const { userId, associatedFormId } = this.props;
        if (associatedFormId) {
            const allProjects = ProjectModel.list();
            const projectOptions: any = [];
            allProjects.forEach((projectInstance: any) => {
                projectOptions.push({
                    value: projectInstance.props.projectName,
                    label: projectInstance.props.projectName
                });
            });
            return projectOptions;
        }
        return userId && getProjectsOfUser(userId);
    }

    handleConcernSelect = (concernType: string) => { 
        if (concernType === 'Security') {
            this.setState({
                isConcernTypeSecurity: true 
            });
        } else {
            dispatch(actions.change('forms.incidentDetails.securityCategory', ''));
            dispatch(actions.change('forms.incidentDetails.methodOfBreach', ''));
            this.setState({
                isConcernTypeSecurity: false
            });
        }
    }

    isPreliminaryReport = (checked: boolean) => { 
        dispatch(actions.change('forms.incidentDetails.finalReport', !checked));
    }

    isFinalReport = (checked: boolean) => {
        dispatch(actions.change('forms.incidentDetails.preliminaryReport', !checked));
    } 

    onShoreChange = (checked: boolean) => {
        dispatch(actions.change('forms.incidentDetails.offShore', !checked)); 
    }
    offShoreChange = (checked: boolean) => {
        dispatch(actions.change('forms.incidentDetails.onShore', !checked));
    }


    changeDateOfIncident = (date: string) => {
        this.setState({dateReportedMinDate: date})
        if(this.props.forms.incidentDetails.associatedFormId==='No Report') {
            this.setState({dateReportedMaxDate: date})
        }
        let value: any = new Date(date);
        if(differenceInCalendarDays(value, new Date(this.props.forms.incidentDetails.dateReported) )>0) {
            dispatch(actions.change('forms.incidentDetails.dateReported', format(value, 'yyyy-MM-dd')));
        } 
    }
    dateReported = (value: string) => {
        this.setState({dateOfIncidentMinDate: value}) 
    }

    renderGeneralInformationForm = () => {
        const { incidentNotificationInstance, forms } = this.props;  
        let editInstance = this.props.editInstance; 
        return (
            <div className="general-information">
                <fieldset className="form-top-fieldset">
                    <legend><span style={{color: 'yellow'}}>General Information Form</span></legend>
                    <Row className="checkbox-row show-grid">
                        <Col sm={{span: 4, offset: 4}} xs={12}  style={{marginBottom:'10px'}}>
                            <Label required sm={12} htmlFor="associatedFormId" /* style={{marginTop:'-21px'}} */>
                                Associated Incident Notification Form:
                            </Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="associatedFormId"
                                    type="dropdown"
                                    model=".associatedFormId" 
                                    isDisabled={this.props.associatedFormId ? true : false}
                                    defaultValue={this.props.associatedFormId || null}
                                    optionComponent={SelectOptionComponent}
                                    valueComponent={ValueComponent}
                                    onSelect={this.handleAssociatedFormSelect}
                                    menuItems={ this.props.incidentNotificationInstances && getAllIncidentNotificationFormsOptions( this.props.incidentNotificationInstances, this.props.associatedFormId?false:false)}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={3} className="date-time-column">
                            <Label required sm={12} htmlFor="dateOfIncident">Date Of Incident:</Label>
                            <Col sm={12} className="input date-input">
                                <RRFInput
                                    id="dateOfIncident"
                                    model=".dateOfIncident"
                                    defaultValue={incidentNotificationInstance? format(new Date(incidentNotificationInstance.props.dateOfIncident), 'yyyy-MM-dd') : (editInstance ? format(new Date(editInstance.props.dateOfIncident), 'yyyy-MM-dd') : (this.props.forms.incidentDetails.associatedFormId==='No Report'? this.state.currentDate:this.state.currentDate) ) }
                                    type="date" 
                                    maxdate={!incidentNotificationInstance? format(new Date(), 'yyyy-MM-dd'): this.state.dateOfIncidentMinDate} 
                                    isDisabled={this.props.forms.incidentDetails.associatedFormId==='No Report'?false:true} 
                                    onChange={this.changeDateOfIncident}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} className="date-time-column">
                            <Label required sm={12} htmlFor="d">Time of Incident:</Label>
                            <Col sm={12} className="input time-input">
                                <RRFInput
                                    id="timeOfIncident"
                                    type="time"
                                    model=".timeOfIncident" 
                                    defaultValue={incidentNotificationInstance? incidentNotificationInstance.props.timeOfIncident : (editInstance ? editInstance.props.timeOfIncident : (this.props.forms.incidentDetails.associatedFormId==='No Report'? this.state.currentTime:this.state.currentTime) ) } 
                                    isDisabled={this.props.forms.incidentDetails.associatedFormId==='No Report'?false:true}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} className="date-time-column">
                            <Label required sm={12} htmlFor="dateReported">Date Reported:</Label>
                            <Col sm={12} className="input date-input ">
                                <RRFInput
                                    id="dateReported"
                                    type="date"
                                    model=".dateReported"
                                    defaultValue={ incidentNotificationInstance ? format(new Date(incidentNotificationInstance.props.date), 'yyyy-MM-dd') : (editInstance ? format( new Date(editInstance.props.dateReported), 'yyyy-MM-dd') : (this.props.forms.incidentDetails.associatedFormId==='No Report' ? this.state.currentDate : this.state.currentDate) ) }
                                    mindate={this.state.dateReportedMinDate}
                                    maxdate={ !incidentNotificationInstance ? format(new Date(), 'yyyy-MM-dd') : (this.state.dateReportedMaxDate ? format(new Date(this.state.dateReportedMaxDate), 'yyyy-MM-dd') : null) } 
                                    isDisabled={this.props.forms.incidentDetails.associatedFormId==='No Report' ? false : true}
                                    onChange={this.dateReported}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} className="date-time-column">
                            <Label required sm={12} htmlFor="timeReported">Time Reported:</Label>
                            <Col sm={12} className="input time-input">
                                <RRFInput
                                    id="timeReported"
                                    type="time"
                                    model=".timeReported" 
                                    defaultValue={incidentNotificationInstance? format(new Date('01-01-2021 '+incidentNotificationInstance.props.time),'HH:mm') : (editInstance ? format(new Date('01-01-2021 '+editInstance.props.time),'HH:mm') : (this.props.forms.incidentDetails.associatedFormId==='No Report'? this.state.currentTime:this.state.currentTime) ) }  
                                    isDisabled={this.props.forms.incidentDetails.associatedFormId==='No Report'?false:true}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="checkbox-row show-grid">
                        <Col sm={4}>
                            <label
                                className="checkbox-label"
                                htmlFor="photosIncluded"
                            >
                                Photos Included:
                        </label>
                            <RRFInput
                                type="checkbox"
                                id="photosIncluded"
                                model=".photosIncluded"
                                defaultValue={editInstance && editInstance.props.photosIncluded===1?true:null}
                            />
                            <label
                                className="checkbox-field"
                                htmlFor="photosIncluded"
                            >
                            </label>
                        </Col>
                        <Col sm={4} className="checkbox-center">
                            <label
                                className="checkbox-label" htmlFor="preliminaryReport"
                            >
                                Preliminary Report:
                        </label>
                            <RRFInput
                                type="checkbox"
                                id="preliminaryReport"
                                model=".preliminaryReport"
                                onChange={this.isPreliminaryReport} 
                                defaultValue={!editInstance || (editInstance && editInstance.props.preliminaryReport===1 && !forms.incidentDetails.finalReport)?true:null} 
                                isDisabled={UserModel.getAccessLevel()==='L1' || UserModel.getAccessLevel()==='L2'? false:true}
                            />
                            <label
                                className="checkbox-field"
                                htmlFor="preliminaryReport"
                            >
                            </label>
                        </Col>
                        <Col sm={4}>
                            <label
                                className="checkbox-label" htmlFor="finalReport"
                            >
                                Final Report:
                            </label>
                            <RRFInput
                                type="checkbox"
                                id="finalReport"
                                model=".finalReport"
                                onChange={this.isFinalReport}
                                defaultValue={editInstance && editInstance.props.finalReport===1?true:null}
                                isDisabled={UserModel.getAccessLevel()==='L1' || UserModel.getAccessLevel()==='L2'? false:true}
                            />
                            <label
                                className="checkbox-field"
                                htmlFor="finalReport"
                            >
                            </label>
                        </Col>
                    </Row>
                    <Row className="show-grid projects"> 
                        <Col sm={6} xs={12}> 
                            <Label sm={12} htmlFor="generalContractor">General Contractor:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.generalContractor} model="forms.incidentDetails.generalContractor" /> 
                            <Col sm={12} className="input">
                                {/* <br /> */}
                                <RRFInput
                                    id="generalContractor"
                                    placeholder="Enter general contractor..."
                                    type="text"
                                    className="form-control"
                                    model=".generalContractor" 
                                    defaultValue={editInstance && editInstance.props.generalContractor }
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>  
                            <Label sm={12} htmlFor="SubContractor">SubContractor:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.subContractor} model="forms.incidentDetails.subContractor" />  
                            <Col sm={12} className="input">
                                {/* <br /> */}
                                <RRFInput
                                    id="subContractor"
                                    placeholder="Enter sub contractor..."
                                    type="text"
                                    className="form-control"
                                    model=".subContractor"
                                    defaultValue={editInstance && editInstance.props.subContractor }
                                />
                            </Col>
                        </Col>
                    </Row>
                   <Row className="checkbox-row">
                        <Col sm={4}>
                            <label htmlFor="workRelated">Work Related:</label>
                            <div className="radio-buttons">
                                <label htmlFor="workRelated">Yes</label>
                                <RRFInput
                                    id="workRelatedYes"
                                    type="radio"
                                    model=".workRelated"
                                    radioValue="yes"
                                    radioName="workRelated" 
                                    defaultValue={editInstance && editInstance.props.workRelated==='yes' ? 'yes' : null }
                                /><label htmlFor="workRelatedYes"></label>
                                <label htmlFor="workRelated">No</label>
                                <RRFInput
                                    radioValue="no"
                                    id="workRelatedNo"
                                    type="radio"
                                    model=".workRelated"
                                    radioName="workRelated"
                                    defaultValue={editInstance && editInstance.props.workRelated==='no' ? 'no' : null }
                                /> <label htmlFor="workRelatedNo"></label>
                            </div>
                        </Col>
                        <Col className="checkbox-center" sm={4}>
                            <label className="checkbox-label" htmlFor="onShore">Onshore:</label>
                            <RRFInput
                                type="checkbox"
                                id="onShore"
                                model=".onShore" 
                                onChange={this.onShoreChange}
                                defaultValue={!editInstance || (editInstance && editInstance.props.onShore===1)?true:false}
                            />
                            <label style={{ marginBottom: '-10px' }} htmlFor="onShore"></label>
                        </Col>
                        <Col sm={4}>
                            <label className="checkbox-label" htmlFor="offShore">Offshore:</label>
                            <RRFInput
                                type="checkbox"
                                id="offShore"
                                model=".offShore"
                                onChange={this.offShoreChange}
                                defaultValue={editInstance && editInstance.props.offShore===1?true:false}
                            />
                            <label style={{ marginBottom: '-10px' }} htmlFor="offShore"></label>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="category">Category:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="category"
                                    placeholder="Select category..."
                                    type="dropdown"
                                    menuItems={[
                                        { label: 'Cat 1', value: 'Cat 1' },
                                        { label: 'Cat 2', value: 'Cat 2' },
                                        { label: 'Non Stewardable', value: 'Non Stewardable' },
                                        { label: 'Other', value: 'Other' },
                                    ]}
                                    /* className="form-control" */
                                    model=".category" 
                                    defaultValue={editInstance && editInstance.props.category}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <ConcernDropdown
                                /* onSelect={ !editInstance && this.handleConcernSelect } */
                                model=".concernType"
                                id="concernType" 
                                defaultValue={editInstance && editInstance.props.concernType}
                            />
                        </Col>
                        <Col sm={3} xs={12}>
                            <ProjectInput model=".projectName" id="project" defaultValue={ (editInstance && editInstance.props.projectName ) || this.props.forms.incidentDetails.projectName || ( this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0] )} />
                        </Col>
                        <Col sm={3} xs={12}>
                            <GroupInput model=".groupName" id="group" defaultValue={( editInstance && editInstance.props.groupName) || this.props.forms.incidentDetails.groupName || (this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]) } />
                        </Col> 
                    </Row> 
                </fieldset >
                {this.state.isConcernTypeSecurity ? <div className="form-middle"
                    style={{ borderBottom: '1px solid #c1c1c0' }} >
                    <SecurityForm />
                </div> : ''}
                <Row className="show-grid"
                    style={{ borderRight: '1px solid #c1c1c0', borderLeft: '1px solid #c1c1c0', margin: '0 2px' }} >
                    <Col sm={6} xs={12}>
                        <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                        <Col sm={12} className="input">
                            <FileInput
                                model=".incidentReportImages"
                                multiple={true}
                                id="upload-images" 
                                defaultValue={editInstance && editInstance.props.imageUrls}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                        <Col sm={12} className="input">
                            <VideoInput
                                model=".incidentReportVideos"
                                multiple={true}
                                id="upload-videos" 
                                defaultValue={editInstance && editInstance.props.videoUrls}
                            />
                        </Col>
                    </Col>
                </Row>
            </div >
        );
    }

    render() {
        return (
            <Async
                identifier="GeneralInformationForm"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderGeneralInformationForm()}
            />
        );
    }
}
export function mapStateToProps(state: any, ownProps :any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);
    const incidentNotificationInstances = IncidentNotificationModel.list(); 
    return { userId, userInstance , incidentNotificationInstances, forms: state.forms };
}

export const GeneralInformationForm = connect<{}, {},
    IGeneralInformationFormProps>(mapStateToProps)(GeneralInformationFormImpl);
