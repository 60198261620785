import * as React from 'react';
import { getAllGroups } from '../../services/groupService';
import { GroupModel } from '../../model/GroupModel';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Async } from '../reusableComponents/Async';
import { Label } from '../reusableComponents/FormComponents/Label';
import { Container as Grid, Row, Col } from 'react-bootstrap';
import { Alert } from '../Alert';
import './jsaForm.scss';
import { format } from 'date-fns';
import { Button } from '../reusableComponents/FormComponents/Button';
import { connect } from 'react-redux';
import { getFormData, getJSAFormValues } from '../../services/jsaFormService';
import { withRouter } from 'react-router-dom';
import { IHistory, IAnswer, IForms } from '../../interfaces';
import { JSAModel, IJobSafetyStep, IJSAModelProps } from '../../model/JSAModel';
import { Loader } from '../reusableComponents/Loader';
import { UserModel } from '../../model/UserModel';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { FileInput } from '../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { Form } from '../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { constructJSAAnswers, dispatch, isFormEmpty, showAlert, isArrayEmptyAndAlert } from '../../utils/generalUtils';
import { ManualSignature } from '../reusableComponents/ManuallyGenerateSignature/ManualSignature'; 
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput'; 
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IJSAFormProps {
    userUID: string;
    userId: string;
    id?: string;
    jsaNum?: string;
    location?: string;
    typeOfWork?: string;
    groupName?: string;
    projectName?: string;
    followUp?: string;
    companyName?: string;
    date?: string;
    workToPerform?: string;
    answers?: IJobSafetyStep[];
    history?: IHistory;
    instance?: JSAModel;
    onSubmit?: (userId: string, date: string, answerData: IAnswer[], group: string, project: string) => void;
    userInstance?: UserModel; 
    company: string;
    editId: string;
    editInstance: any;
    forms: IForms; 
} 
export interface IJSAFormState {
    noOfRows: number[];
    signature: File | string;
    signatureURL: string;
    isSignatureUploaded: boolean;
    signNew: boolean;
    currentDate: string;
    groupMembers: any[];
    nonUsersStep: number[];
    isAcknowleged: boolean;
}

export class JSAFormImpl extends React.PureComponent<IJSAFormProps, IJSAFormState> {

    constructor({ instance, editInstance }: any) {
        super(instance); 
        let value = editInstance && editInstance.props.answers.length>0?[]:[1]; 
        let signNew = true;
        if (!instance || !editInstance) {
            dispatch(actions.push(`forms.jsaForm.stepData`, ''));
            dispatch(actions.push(`forms.jsaForm.stepDescription`, ''));
            dispatch(actions.push(`forms.jsaForm.hazardsRisk`, ''));
            dispatch(actions.push(`forms.jsaForm.controls`, ''));

        }
        if (instance) {
            instance.props.answers.forEach((val: any, index: number) => {
                value.push(index);
                this.tableRows++;
            });
        }
        let isAcknowleged = false;
        if(editInstance) {  
            editInstance.props.answers.forEach((val: any, index: number) => {
                value.push(index);
                this.tableRows++;
            });  
            if(editInstance.props.signatureUrl)
                signNew = false;
            if(editInstance.props.acknowledgement==='1')
                isAcknowleged = true;
        }
        this.state = {
            noOfRows: value,
            signature: '',
            signatureURL: '', isSignatureUploaded: false,
            signNew: signNew,
            currentDate: format(new Date(), 'yyyy-MM-dd'), groupMembers: [], nonUsersStep: [1], isAcknowleged: isAcknowleged
        };
    } 
    rowNo: any; 
    nonUsersRows = 1; 

    getChildContext() {
        return { formModel: 'forms.jsaForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    jobSafetySteps: IJobSafetyStep[] = [];

    tableRows = 1;
    stepDescription = [];
    hazardsRisk = [];
    controls = [];
    stepData: IJobSafetyStep[] = [];

    renderFilledTable = () => {
        const { instance } = this.props;  
        if(!instance)
            return;
        return this.state.noOfRows.map((a, index) => {
            return instance.props.answers && this.renderTableRows(index, instance.props.answers[index]);
        });
    }
    renderEditFilledTable = () => {
        const { editInstance } = this.props; 
        return this.state.noOfRows.map((a, index) => {  
            return this.renderTableRows(index, editInstance.props.answers[index]);
        }); 
    }

    promise = async () => {
        const { editId, editInstance } = this.props;
        if(editId && !editInstance) {
            await getFormData('jsa', this.props.editId);
        }
        if (GroupModel.list().length === 0) {
            await getAllGroups();
        } 
        //await getAllUsers();
        //await this.getUsersOptions();
        if(editInstance) {
            await this.getUsersOptions(editInstance.props.groupName);
            this.nonUsersRows = parseInt(editInstance.props.nonAppUsersCount) || 1;
            if(this.nonUsersRows>1) {
                this.setState({
                    nonUsersStep: []
                }); 
                for(let i = 0; i < this.nonUsersRows; i++) {
                    this.setState({
                        nonUsersStep: [...this.state.nonUsersStep, i+1]
                    }); 
                }
            }
            editInstance.props.nonAppUsersList.map((instance: any, index: any) => {  
                dispatch(actions.change(`forms.jsaForm.nonAppUsers[${index}].uuid`, editId && editInstance && index<editInstance.props.nonAppUsersList.length && editInstance.props.nonAppUsersList[index]['uniqueId']));
            })
        }
        return null;
    }
 
    getUsersOptions = (group?: string) => { 
        dispatch(actions.change(`forms.jsaForm.appUsers`,''));
        const users = UserModel.list();
        this.setState({groupMembers: []});
        let groupMembers: any = [];
        let firstNames: any[] = [];
        (users || []).forEach( (user:any) => { 
            if(user.props.groups.includes(group)) {
                let firstName = user.props.firstName;
                firstNames.push(firstName);
                if (firstNames.indexOf(firstName) > -1) {
                    firstName = user.props.firstName + ' ' + user.props.middleName + ' ' + user.props.lastName;
                }
                groupMembers.push({ label: firstName, value: user.props.userUID.toString() });
            }
        });

        this.setState({groupMembers: groupMembers});
    } 
    incrementRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.jsaForm.stepDescription`, ''));
        dispatch(actions.push(`forms.jsaForm.hazardsRisk`, ''));
        dispatch(actions.push(`forms.jsaForm.controls`, ''));
        this.setState({
            noOfRows: [...this.state.noOfRows, ++this.tableRows]
        });
    }

    decrementRows = (indexVal: number) => { 
        --this.tableRows;
        dispatch(actions.remove(`forms.jsaForm.stepDescription`, indexVal));
        dispatch(actions.remove(`forms.jsaForm.hazardsRisk`, indexVal));
        dispatch(actions.remove(`forms.jsaForm.controls`, indexVal));
        this.setState({
            noOfRows: this.state.noOfRows.slice(0, - 1)
        });
    }

    renderTableRows = (index: number, jobSafetyStep?: IJobSafetyStep) => {
        return (
            <Row key={index} className="input-fields"> 
                    <Col sm={1}
                        className="trash"
                        style={{ cursor: 'pointer', textAlign: 'center', padding: '2px' }} >
                        {this.state.noOfRows.length > 1 ? <i onClick={() => this.decrementRows(index)}
                            className="fa fa-lg fa-trash"
                            aria-hidden="true"
                        >
                        </i> : null}
                    </Col>
                    <Col
                        xs={1}
                        className="step-no"
                        style={{ width: '10px', padding: '2px' }} sm={1}>{index + 1}
                    </Col>
                
                    <Col sm={3} className="textarea-input"> 
                        <VoiceRecognition labelText="" defaultValue={jobSafetyStep && jobSafetyStep.stepDesc} model={`forms.jsaForm.stepDescription[${index}]`} />
                        <RRFInput
                            type="textarea"
                            className="form-control step-description"
                            placeholder="Enter step description..."
                            model={`.stepDescription[${index}]`}
                            defaultValue={jobSafetyStep ? jobSafetyStep.stepDesc : ''}
                        />
                    </Col>
                    <Col sm={3} className="textarea-input"> 
                        <VoiceRecognition labelText="" defaultValue={jobSafetyStep && jobSafetyStep.hazards} model={`forms.jsaForm.hazardsRisk[${index}]`} />
                        <RRFInput
                            type="textarea"
                            className="form-control hazards-risk"
                            placeholder="Enter hazards risk..."
                            model={`.hazardsRisk[${index}]`}
                            defaultValue={jobSafetyStep ? jobSafetyStep.hazards : ''}
                        />
                    </Col>
                    <Col sm={3} className="textarea-input">
                        <VoiceRecognition labelText="" defaultValue={jobSafetyStep && jobSafetyStep.controls} model={`forms.jsaForm.controls[${index}]`} />
                        <RRFInput
                            type="textarea"
                            className={`form-control ${this.state.noOfRows.length > 1 ? 'controls-trash' : 'controls'}`}
                            placeholder="Enter controls..."
                            model={`.controls[${index}]`}
                            defaultValue={jobSafetyStep ? jobSafetyStep.controls : ''}
                        />
                    </Col>
                 
            </Row >
        );
    }

    renderFormTable = () => {
        const { instance, editInstance} = this.props; 
        return (
            <Grid style={{ width: '100%' }}>
                <Row className="show-grid jsa-table">
                    <Col style={{ width: '20px', padding: '2px' }} sm={1}>
                    </Col >
                    <Col style={{ width: '10px', padding: '2px' }} sm={1}>
                    </Col>
                    <Col style={{ margin: '0 3%' }} sm={3}>
                        <span style={{ color: 'red' }} >*</span>
                        <span style={{ color: 'yellow' }} >Step Description</span>
                    </Col>
                    <Col sm={3} style={{ margin: '0 3%' }}>
                        <span style={{ color: 'red' }} >*</span>
                        <span style={{ color: 'yellow' }} >Hazards/Risks</span>
                    </Col>
                    <Col style={{ margin: '0 3%' }} sm={3}>
                        <span style={{ color: 'red' }} >*</span>
                        <span style={{ color: 'yellow' }} >Controls</span>
                    </Col>
                </Row>
                {
                    instance ? this.renderFilledTable() : (editInstance ? this.renderEditFilledTable() :
                        this.state.noOfRows.map((a, index) => {
                            return this.renderTableRows(index);
                        }) )}
                <div className="table-buttons">
                    <button className="add-row"
                        onClick={this.incrementRows}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;Add Another Step
                        </button>
                </div>
            </Grid>
        );
    }

    handleSubmit = ({ location, typeOfWork, stepDescription, hazardsRisk, controls, jsaNum, workToPerform, companyName, groupName, projectName, approval, date, locationName, appUsers, nonAppUsers, nonAppUsersEmail,acknowledgement, isApproved }: IJSAModelProps) => { 
        const { editInstance } = this.props
        const stepValues: {
            step: number
            stepDesc: string
            hazards: string
            controls: string
        }[] = [];
        const { userUID } = this.props;

        if(stepDescription && hazardsRisk && controls) {
            for (let i = 0; i < this.tableRows; i++) {
                stepValues.push({
                    step: i + 1,
                    stepDesc: stepDescription[i],
                    hazards: hazardsRisk[i],
                    controls: controls[i]
                });
            }
        }  
        const answerData = constructJSAAnswers(
            stepValues,
            location,
            typeOfWork,
            jsaNum,
            workToPerform,
            companyName, locationName, date, approval
        );
        if(editInstance && editInstance.props.signatureUrl && !this.state.isSignatureUploaded) {
            answerData.push({'answer': editInstance.props.signatureUrl, 'quesNum': 0, 'quesType': 'signatureUrl' });
        } 
        const values: any = { companyName,locationName, location, projectName, groupName, stepValues, date, typeOfWork, jsaNum, workToPerform };   
        if (isFormEmpty(getJSAFormValues(values))) {
            showAlert(EMPTY_FORM_MESSAGE, 'jsa-form', 'danger');
            return;
        }
        if(isArrayEmptyAndAlert( {stepDescription: stepDescription, hazardsRisk: hazardsRisk, controls: controls} )) {
            showAlert(EMPTY_FORM_MESSAGE, 'jsa-form', 'danger');
            return;
        } 
        let appUsersList = (appUsers && (Array.isArray(appUsers)?appUsers: (appUsers.split(',') || []) ) ) || [] ;
        if(appUsersList.length<1 && nonAppUsers.length<1) {
            showAlert('Please fill participants list', 'jsa-form', 'danger');
            return;
        }   
        answerData.push({'answer': (acknowledgement?'1':'0'), 'quesNum': 0, 'quesType': 'acknowledgement' }); 
        answerData.push({'answer': appUsersList.length.toString(), 'quesNum': 0, 'quesType': 'appUsersCount' }); 
        answerData.push({'answer': nonAppUsers.length.toString(), 'quesNum': 0, 'quesType': 'nonAppUsersCount' }); 
        return this.props.onSubmit && this.props.onSubmit(userUID, date, answerData, groupName, projectName);
    }
    saveSignature = (file: any, acceptorSignature: any) => { 
        this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: acceptorSignature,
            signNew: false,
        });  
        dispatch(actions.change(`forms.jsaForm.jsaSignature`, acceptorSignature)); 
    }

    saveGeneratedSignature = (file: any, base64: any) => { 
        this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: base64,
            signNew: false,
        }); 
        dispatch(actions.change(`forms.jsaForm.jsaSignature`, base64));
    }
    renderSignatureField = () => {
        if (this.props.userInstance || ( this.props.editInstance && this.props.editInstance.props.signatureUrl) ) {
            return <Row className="show-grid" style={{ paddingBottom: '20px' }} > 
                <Col sm={12} style={{ color: 'green' }} className="signature">
                    <img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }}
                        src={this.state.signatureURL || (this.props.userInstance && this.props.userInstance.props.signatureUrl) || this.props.editInstance.props.signatureUrl} alt="SafeConnect" />
                    <div>
                        <button
                            className="button"
                            style={newSign}
                            onClick={() => this.setState({ signNew: true })}>
                            Sign New
                  </button>
                    </div>
                </Col>
            </ Row >;
        }

        return '';
    }
    incrementNonUsersEventRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.jsaForm.nonAppUsers`, '')); 
        this.setState({
            nonUsersStep: [...this.state.nonUsersStep, ++this.nonUsersRows]
        }); 
    } 
    decrementNonUsersEventRows = async (indexVal: number) => { 
        const { forms, editId } = this.props; 
        let nonAppUsers: any = forms.jsaForm.nonAppUsers[indexVal]; 
        dispatch(actions.change(`forms.jsaForm.nonAppUsers[${indexVal}].uuid`, '')); 
        dispatch(actions.change(`forms.jsaForm.nonAppUsers[${indexVal}].username`, '')); 
        dispatch(actions.change(`forms.jsaForm.nonAppUsers[${indexVal}].email`, '')); 
        if(nonAppUsers['uuid'] && editId) { 
            await dispatch(actions.push(`forms.jsaForm.deletedNonAppUsers`, nonAppUsers['uuid'])); 
        } 
        --this.nonUsersRows; 
        await dispatch(actions.remove(`forms.jsaForm.nonAppUsers`, indexVal)); 
        this.setState({
            nonUsersStep: this.state.nonUsersStep.slice(0, - 1)
        }); 
    }
    renderNonUserRows = (index: any) => {
        const { editInstance } = this.props; 
        return (
            <Row key={index} className="nonUsersRow"> 
                <Col sm={1}
                    className="trash"
                    style={{ cursor: 'pointer', textAlign: 'center', padding: '2px' }} >
                    {this.nonUsersRows > 1 ? <i onClick={() => this.decrementNonUsersEventRows(index)}
                        className="fa fa-lg fa-trash"
                        aria-hidden="true"
                    >
                    </i> : null}
                </Col>
                <Col
                    sm={1}
                    className="step-no"
                    style={{ width: '10px', padding: '2px' }}  >{index + 1}
                </Col>
                <Col sm={5} >
                    <RRFInput
                        type="text"
                        className=""
                        placeholder="Enter participant name"
                        model={`.nonAppUsers[${index}].username`} 
                        defaultValue={editInstance && index<editInstance.props.nonAppUsersList.length && editInstance.props.nonAppUsersList[index]['user']}
                    />
                </Col>
                <Col sm={6} >
                    <RRFInput
                        type="text"
                        className=""
                        placeholder="Enter participant email"
                        model={`.nonAppUsers[${index}].email`}  
                        defaultValue={editInstance && index<editInstance.props.nonAppUsersList.length && editInstance.props.nonAppUsersList[index]['userEmail']}
                    />
                </Col>
            </Row>
        )
    }
    renderUserSelection = () => { 
        const { editInstance } = this.props;
        return <Row className="show-grid">
            <Label required={false} sm={12}>Participants</Label>
            <Col sm={4} className="input">
                <Label sm={12} required htmlFor="appUsers">App Participants:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id={`.appUsers`}
                        type="dropdown"
                        menuItems={this.state.groupMembers} 
                        multi={true}
                        model={`.appUsers`} 
                        defaultValue={editInstance && editInstance.props.appUsers}
                    />
                </Col>
            </Col>
            <Col sm={8} className="input">
                <Label sm={12} required htmlFor="nonAppUsers">Non App Participants:</Label>
                <Grid style={{ width: '100%' }}>
                    { this.state.nonUsersStep.map((a: any, index: any) => {
                            return this.renderNonUserRows(index);
                        })
                    } 
                    <div className="table-buttons">
                        <button className="add-row"
                            onClick={this.incrementNonUsersEventRows}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            &nbsp;Add Another User
                            </button>
                    </div>
                </Grid>
            </Col>
        </Row>
    }
    onTeamChange = () => { 
        dispatch(actions.change(`forms.jsaForm.appUsers`,''));
        this.setState({ groupMembers: [] })
    }
    renderFormContent() {
        const { instance, userInstance, editId, editInstance, history, } = this.props;
        const { currentDate } = this.state; 
        if(editId && editInstance===undefined ) {
            history?.push('/jsa-reports');
        }   
        return ( 
            <Form loader={<SubmissionLoader />} model="forms.jsaForm" onSubmit={this.handleSubmit}>
                <MapInputModal id="jsa-form" />
                <fieldset>
                    <legend><span style={{ color: 'yellow' }}>Job Safety Analysis</span></legend>
                    <Row className="show-grid">
                        <Col sm={4} xs={12}>
                            <Label required sm={12} htmlFor="companyName">Company Name:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="companyName"
                                    type="text"
                                    placeholder="Enter company name..."
                                    model=".companyName"
                                    defaultValue={editId && editInstance ? editInstance.props.company : this.props.userInstance && this.props.userInstance.props.company}
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} required htmlFor="typeOfWork">Title:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="typeOfWork"
                                    type="text"
                                    placeholder="Enter title..."
                                    model=".typeOfWork" maxChar="75"
                                    defaultValue={ editInstance && editInstance.props.typeOfWork}
                                />
                            </Col>
                        </Col> 
                        <LocationInput     
                            companyName={this.props.company}
                            model=".location"
                            id="jsa-form"
                            navigator={navigator}
                            defaultValue={(editInstance && editInstance.props.locationName) }
                            location={editInstance && editInstance.props.location}
                        /> 
                    </Row>
                    <Row className="show-grid">
                        <Col sm={4} xs={12}>
                            <Label sm={12} required htmlFor="workToPerform">Work Description:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="workToPerform"
                                    type="text"
                                    placeholder="Enter work to be performed..."
                                    model=".workToPerform" 
                                    defaultValue={ editInstance && editInstance.props.workToPerform}
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label required sm={12} htmlFor="date">Date:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="date"
                                    type="date"
                                    model=".date" 
                                    defaultValue={editInstance && editInstance.props.dateOfAnalysis ? format(new Date(editInstance.props.dateOfAnalysis), 'yyyy-MM-dd') :currentDate  }  
                                    mindate={editInstance? format(new Date(editInstance.props.date), 'yyyy-MM-dd'): format(new Date(), 'yyyy-MM-dd')} 
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label required sm={12} htmlFor="jsaN">JSA N&deg;:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="jsaN"
                                    type="text"
                                    placeholder="Enter JNS N&deg;..."
                                    model=".jsaNum"
                                    defaultValue={editInstance ? editInstance.props.jsaNum:( instance ? instance.props.jsaNum : '') }
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                         <Col sm={4} xs={12}>
                            <ProjectInput model=".projectName" id="project" defaultValue={editInstance ? editInstance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} onTeamChange={this.onTeamChange}/>
                        </Col>
                        <Col sm={4} xs={12}>
                            <GroupInput model=".groupName" id="group" defaultValue={editInstance ? editInstance.props.groupName : '' /* this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0] */} onGroupChange={this.getUsersOptions}/>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="approval">Approval:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="approval"
                                    type="text"
                                    placeholder="Enter approval email"
                                    model=".approval"
                                    defaultValue={editInstance ? editInstance.props.approval:( instance ? instance.props.approval : '') }
                                />
                            </Col>
                        </Col>
                    </Row>
                    {this.renderUserSelection()}
                    {this.renderFormTable()}
                    <Row className="show-grid">
                        <Col sm={6} xs={12}>
                            <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                            <Col sm={12} className="input">
                                <FileInput
                                    model=".jsaImages"
                                    multiple={true}
                                    id="upload-images"
                                    defaultValue={editInstance && editInstance.props.imageUrls}
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                            <Col sm={12} className="input">
                                <VideoInput
                                    model=".jsaVideos"
                                    multiple={true}
                                    id="upload-videos"
                                    defaultValue={editInstance && editInstance.props.videoUrls}
                                />
                            </Col>
                        </Col> 
                    </Row> 
                    <Row className="show-grid">
                        <Col sm={6} xs={12} className="e-signature jsa-form">
                            <Label sm={12} required={false} htmlFor="upload-images">Signature: </Label> 
                            <Col sm={12} className="input">
                                {this.state.signNew ? userInstance &&
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={`${this.props.userInstance && this.props.userInstance.props.firstName} ${this.props.userInstance && this.props.userInstance.props.lastName}`}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField()}
                            </Col> 
                        </Col>
                     
                        { editInstance && <><Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-videos">Acknowledgement Files:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".ackAttach"
                                    multiple={true}
                                    id="upload-videos" 
                                    uploadedImages={editInstance && editInstance.props.ackAttachUrls}
                                />
                            </Col>
                        </Col> 
                        <Col sm={12} xs={12} style={{marginTop: '10px'}}>
                            <Label required={false} sm={12} >Acknowledged Participants: {<span style={{fontSize: '18px', fontWeight:'bold'}}>({`${editInstance.props.totalFormAck}/${( (editInstance?.props.appUsers.length || 0) ) + ( (editInstance?.props.nonAppUsers.length) || 0)}`})</span>}</Label>
                        </Col>
                        <Col sm={12} xs={12} style={{marginTop: '10px'}}>
                            <Label required={false} sm={12} >Created User Acknowledgement:</Label>
                            <div style={{color: 'white'}}> 
                            <RRFInput
                                type="checkbox"
                                id="acknowledgement"
                                model=".acknowledgement" 
                                defaultValue={editInstance && editInstance.props.acknowledgement==='1'?true:false}
                                onChange={this.onAcknowledgementChange}
                            /> <span style={{paddingLeft: '10px'}}> I heareby, acknowledge that all participants mentioned above are aware of this Job Safety Analysis.</span></div>
                        </Col> </>}
                        {/* {UserModel.getAccessLevel()=='L2' && this.state.isAcknowleged && 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}>
                                <Label sm={12}>Manager Approval</Label>
                                <Col sm={12} xs={12} style={{textAlign: 'center'}}> 
                                    <RRFInput
                                        id="isApproved"
                                        model=".isApproved"
                                        type="checkbox" 
                                        defaultValue={editInstance && editInstance.props.isApproved=='1'?true:false} 
                                    />
                                    <label htmlFor="isApproved"></label>
                                </Col>
                                <Col sm={12} className="input" style={{textAlign:'center'}}>  
                                    <label style={{textAlign: 'center', color:'white' }}>({editInstance?.props.approvedBy || userInstance?.props.firstName})</label> 
                                </Col> 
                            </Col> 
                        } */}
                    </Row>
                </fieldset>
                <Row className="show-grid">
                    <div className="form-button" style={{ marginTop: '20px', textAlign: 'center' }} >  
                        <Alert id="jsa-form" className="danger-alert" />
                        <Button style={{
                            color: 'rgb(84, 121, 175)',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white',
                        }}
                            redirectTo="goBack" type="button"
                            onClick={() => this.props.history?.goBack() }
                        >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button>
                        <Button style={{
                            color: '#FFFFFF',
                            border: 'none',
                            backgroundColor: '#26A65B'
                        }}
                        >
                            <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                &nbsp;
                            { editInstance ? 'Update Analysis' : 'Submit Analysis' }
                           
                        </Button>
                    </div>
                </Row>
            </Form> 
        );
    }
    onAcknowledgementChange = (value: any) => { 
        this.setState({isAcknowleged: value})
    }
    render() {
        return (
            <Async
                identifier="JSAForm"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderFormContent()}
            />
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const groupInstances = GroupModel.list(state);
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId); 
    const company = state.login.get('company'); 
    let editInstance = JSAModel.get(ownProps.match.params.id); 
    const jsaId = ownProps.match.params.templateId;  
    if(jsaId) {
        editInstance = JSAModel.get(jsaId); 
    }
    const editId = ownProps.match.params.id; 

    return {
        groupInstances,
        userInstance,
        userUID: state.login.get('userUID'),
        userId, company, editInstance, editId, forms: state.forms,  
    };
}

export const JSAForm = withRouter(connect<IJSAFormProps, any, any>(mapStateToProps)(JSAFormImpl));
const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};
