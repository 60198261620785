import {combineForms} from 'react-redux-form';
import {format} from 'date-fns';

export const userForm = {
    userId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    accessLevel: '',
    jobTitle: '',
    jobDesc: '',
    password: '',
    email: '',
};

export const incidentDetails = { 
    dateOfIncident: '',
    timeOfIncident: '',
    dateReported: '',
    timeReported: '',
    dateReturnedToWork: format(new Date(), 'yyyy-MM-dd'),
    dateReportedToAgency: format(new Date(), 'yyyy-MM-dd'),
    endorsementDate: format(new Date(), 'yyyy-MM-dd'),
    approvalDate: format(new Date(), 'yyyy-MM-dd'),
    dateAccepted: format(new Date(), 'yyyy-MM-dd'),
    photosIncluded: false,
    preliminaryReport: false,
    finalReport: false,
    generalContractor: '',
    subContractor: '',
    workRelated: 'no',
    category: '',
    offShore: false,
    onShore: false,
    incidentSummary: '',
    incidentDetailedDesc: '',
    immediateCorrectiveAction: '',
    jobTask: '',
    locationName: '',
    equipInvolved: '',
    weather: '',
    lighting: '',
    wind: '',
    seas: '',
    properPPE: 'no',
    selfSupervised: 'no',
    incidentWitnessed: 'no',
    name: '',
    employee: false,
    contractor: false,
    drugTestConducted: 'no',
    sufferEmployeeName: '',
    company: '',
    jobPosition: '',
    timeOnJob: '',
    natureOfInjury: '',
    bodyPart: '',
    medicalResponseTaken: '',
    statusUpdate: '',
    eventLeadingToInjury: '',
    actualDaysLost: '',
    firstAidTreatment: '',
    estimatedDaysLost: '',
    actualSeverity: '',
    potentialSeverity: '',
    securityCategory: '',
    methodOfBreach: '',
    agencyNotified: false,
    agencyName: '',
    reportedToAgencyBy: '',
    notifiedAtAgency: '',
    costIncurred: false,
    totalCost: 0,
    emOwnershipPercentage: 0,
    costType: '',
    costCategory: '',
    ['8010Required']: 'no',
    directCause: '',
    rootCause: '',
    incidentInvestigation: [{
        actionItems: '',
        partyResponsible: '',
        targetDate: ''
    }],
    incidentActivity: '',
    incidentConditions: '',
    workDirection: '',
    concernType: '', 
    associatedFormId: '',
    groupName: '',
    incidentReportImages: [],
    projectName: '',
    isFinalSubmit: '',

};

const projectForm = {
    projectName: '',
    projectDesc: '',
    groups: []
};

const groupForm = {
    groupName: '',
    groupDesc: '',
    groupMembers: '',
};
const locationForm = {
    region: '',
    locationName: '',
    address: '',
}
const designationForm = {
    designation: '',
    status: '',
    priority: '',
}

const incidentNotificationForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'),
    timeOfIncident: format(new Date(), 'HH:mm'),
    location: '',
    specifiedLocation: '',
    injuredPartyIdNo: '',
    injuredPartyWorkFunction: '',
    activityBeingPerformed: '',
    team: '',
    concernType: '',
    activity: '',
    injurySummary: '',
    injuryDiagnosis: '',
    injuryBodyPart: '',
    injuryClassification: '',
    followUp: false,
    groupName: '',
    projectName: '',
    userId: '',
    latitude: '',
    longitude: '',
    formId: '',
    key: '',
};

const oiForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'),
    timeOfIncident: format(new Date(), 'HH:mm'),
    observer: '',
    specifiedLocation: '',
    observerPosition: '',
    observationType: '',
    concernType: '',
    nearMiss: false,
    observation: '',
    consequence: '',
    actionTaken: '',
    followUp: '',
    groupName: '',
    projectName: '',
    /* microphoneText: '', */
};

const personalSafetyForm = {
    credebilityHelp: '',
    credibilityDate: '',
    credibilityComment: '',
    actionHelp: '',
    actionDate: '',
    actionComment: '',
    resilienceHelp: '',
    resilienceDate: '',
    resilienceComment: '',
    engagementHelp: '',
    engagementDate: '',
    engagementComment: '',
    considerationsHelp: '',
    considerationsDate: '',
    considerationsComment: '',
    personalSafetyImages: [],
    personalSafetyVideos: []
};

const jsaForm = {
    jsaNum: '',
    location: '',
    typeOfWork: '',
    groupName: '',
    projectName: '',
    approval: '',
    followUp: '',
    companyName: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    workToPerform: '',
    stepDescription: [],
    hazardsRisk: [],
    controls: [],
    appUsers: '', ackAttach: '', acknowledgement: '', isApproved: '', 
    nonAppUsers: [{
        uuid: '',
        username: '',
        email: ''
    }],
    deletedAckUsers: [],
};

const officeSafetyForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'),
    timeOfIncident: format(new Date(), 'HH:mm'),
    locationName: '',
    projectName: '',
    groupName: '',
    observer: '',
    eyesOnPath: '',
    ascendingDescending: '',
    onTheMoveComments: '',
    toolOrEquipment: '',
    conditionUse: '',
    toolSelectionComments: '',
    liftingPoweringTwisting: '',
    pushingPulling: '',
    manualHandlingComments: '',
    ergonomicsWorkstation: '',
    ergonomicsComments: '',
    lineOfFire: '',
    pinchPoint: '',
    eyesOnTask: '',
    bodyPositionComments: '',
    foodSafety: '',
    cleanliness: '',
    healthHygieneComments: '',
    tailgating: '',
    personalProperty: '',
    barricading: '',
    securityComments: '',
    hskAccessEgress: '',
    hskStorageHousekeeping: '',
    housekeepingComments: '',
    doubleSidePrinting: '',
    recycling: '',
    computerShutdown: '',
    envRecyclingComments: '',

};

const updatePasswordForm = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
};

const loginForm = {
    username: '',
    password: ''
};

const signUpForm = {
    userId: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    jobDesc: '',
    jobTitle: '',
};

const forgotPasswordForm = {
    tempPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const monthlyFilter = {
    month: '',
    year: ''
};

const yearlyFilter = {
    year: ''
};

const dateRangeFilter = {
    startDate: '',
    endDate: ''
};
const commonFilterForm = {
    teams: '', groups: '', filterType: 'summary', year: '', month: '', isSubmitted: false, 
};

const incidentReports = {
    filterType: '',
    checkboxValue: true
};

const oiReports = {
    filterType: ''
};

const goldenRules = {
    rule: [],
    priority: [],
};

const concernType = {
    concern: '',
    activity: []
};


const concernTypesForm = {
    uniqueId: [],
    concern: [],
    priority: [],    
    activities: [{
        activity: '',
        priority: '',
        uniqueId: ''
    }], 
};

const customNotificationForm = {
    audience: '',
    title: '',
    message: '',
    distributeDate: format(new Date(), 'yyyy-MM-dd'),
    attachments: []
};

const feedbackForm = {
    deviceType: '',
    deviceModel: '',
    deviceOs: '',
    problemDescription: '',
    problemRecreation: ''
};

const demoForm = {
    title: '',
};

const reactSelect = {
    selectedLocation: ''
};

const followUpForm = {
    printedName: '', 
    attachments: [],
};
const commonForm = {
    microphoneText: '',
};

const mechForm = {
    eventtype: '',
    eventlocation: '',
    facility: '',
    involvedWorkers: '',
    milepost: '',
    nearstation: '',
    phone: '',
    hours: '',
    carrier: '',
    state: '',
    Name: '',
    date: '',
    time: '',
    address: '',
    city: '',
    stateString: '',
    zip: '',
    reporter: '',
    reporterOther: '',
    certification: '',
    activity: '',
    activityOther: '',
    railroadyears: '',
    yearscraft: '',
    groupsize: '',
    shiftEvent: '',
    otherShiftEvent: '',
    reporterLocation: '',
    otherReporterLocation: '',
    weather: '',
    outdoors: '',
    workarea: '',
    reducedVisibility: '',
    reducedVisibilityDetail: '',
    totalhead: '',
    locomotive: '',
    remoteControl: '',
    distributedPower: '',
    positionInTrain: '',
    noofcars: '',
    noinservice: '',
    carCabControlling: '',
    loads: '',
    empties: '',
    tons: '',
    length: '',
    recordsComplete: '',
    serviceReleased: '',
    requiredDocuments: '',
    movingForRepair: '',
    maintenceDeferred: '',
    type: '',
    otherType: '',
    involvedcar: '',
    location: '',
    operatingRules: '',
    operatingRulesOthers: '',
    descriveEventSituation: ''
}

const transForm = {
    eventtype: '',
    eventlocation: '',
    facility: '',
    involvedWorkers: '',
    milepost: '',
    nearstation: '',
    phone: '',
    hours: '',
    carrier: '',
    state: '',
    name: '',
    date: '',
    time: '',
    address: '',
    city: '',
    stateString: '',
    zip: '',
    reporter: '',
    railroadyears: '',
    yearscraft: '',
    crewsize: '',
    shiftEvent: '',
    reporterLocation: '',
    weather: '',
    outdoors: '',
    workarea: '',
    typeOperation: '',
    transportType: '',
    totalhead: '',
    locomotive: '',
    remoteControl: '',
    cst: '',
    helpers: '',
    distributedPower: '',
    noofcars: '',
    noinservice: '',
    carCabControlling: '',
    loads: '',
    empties: '',
    tons: '',
    length: '',
    type: '',
    operatingRules: '',
    jobCompleted: '',
    trainLocation: '',
    operation: '',
    trainActivity: ''
}

const enggForm = {
    eventtype: '',
    eventlocation: '',
    facility: '',
    involvedWorkers: '',
    milepost: '',
    nearstation: '',
    phone: '',
    hours: '',
    carrier: '',
    state: '',
    name: '',
    date: '',
    time: '',
    address: '',
    city: '',
    stateString: '',
    zip: '',
    reporter: '',
    reporterOther: '',
    activity: '',
    activityOther: '',
    railroadyears: '',
    yearscraft: '',
    workgroup: '',
    shiftEvent: '',
    otherShiftEvent: '',
    reporterLocation: '',
    otherReporterLocation: '',
    weather: '',
    outdoors: '',
    workarea: '',
    reducedVisibility: '',
    reducedVisibilityDetail: '',
    operatingRules: '',
    operatingRulesOthers: '',
    jobCompleted: '',
    type: '',
    typeOther: '',
    operation: '',
    operationOther: '',
    location: '',
    LocationOther: '',
    descriveEventSituation: ''
} 
const closeCallFollowUpForm = {
    description: '',
    action: '',
    targetCompletion: '',
    targetCompletionDate: '',
    actualCompletion: '',
    actualCompletionDate: '',
    status: '',
    attachements: [], 
    //distributeDate: format(new Date(), 'yyyy-MM-dd'),
};
const reportFilterForm = {
    resultType: 'all',
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
    submitDate: format(new Date(), 'yyyy-MM-dd'),
    userName: 'all',
    checklist: 'all',
    filterType: '',
}
const covid19AssessmentForm = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
    q8: false,
    q9: false,
    q10: false,
    q11: false,
    q12: false,
    q13: false,
    language: '',
}
const referenceForm = {
    bannerImage: '',
} 
const checklistFormName = {
    checklistName: '',
}
const auditFormName = {
    checklistName: '', referenceForm: '',
}
const checklistForm = {
    dateOfChecklist: '', projectName: '', groupName: '', totalScore: ''
} 
const attributeFrom = {
    attribute: '',
    name: []
};
const attributeModalForm = {
    attribute: '',
    name: [] 
}
const riskAssessmentForm = { 
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'),
    timeOfIncident: format(new Date(), 'HH:mm'),
    dateOfAssessment: '', typeOfWork: '', workToDescription: '', location: '',
    specifiedLocation: '', projectName: '', groupName: '', 
    riskAssessmentForm: '',
    eventDescription: [], eventCategory: [], probabilityCategory: [], 
    reassessmentOption: '', isApproved: '', isAttested: '', finalAuthorityComments: '',
}
const auditForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'),
    projectName: '',
    groupName: '',
    jsano: '',
    effect: [],
    comments: [],
    attachements: '',
    overallComments: '',
    referenceNo: '',
};

const preExcavationChecklistV2 = {
    excavationDate: new Date(), jobNo: '', streetAddress: '', city: '', state: '', crewForeman: '',oneCallTicketNo: '', 
    contactNo: '', updatTicket: '',  
    naturalGasContactNo: '', pipelineContactNo: '', electricContactNo: '', telephoneContactNo: '', waterContactNo: '', 
    /* isGasMarked: '', isPipelineMarked: '', 
    isElectricMarked: '',
    isTelephoneMarked: '', 
    isWaterMarked: '', */
    isOtherUtilityMarked: '', otherUtility: '', speicalInstructions: '', isScopeOfWork: '',  isWorkToBegin: '', isGasUnmarked: '',  isElectricUnmarked: '',
    isTelephoneUnMarked: '',
    isCableTVUnmarked: '',
    isWaterUnmarked: '',
    isSewerUnmarked: '',
    isSitePhotographed: '', attachments: [],
    deletedFiles: [],
    isExposedMarkedUtilities: '', 
    isMechanizedEquipmentUsed: '', 
    isGasDamaged: '', isElectricDamaged: '', isTelephoneDamaged: '', isCableTvDamaged: '', isWaterDamaged: '', isSewerDamaged: '', isUnknownPipeDamaged: '', isUnknownCableDamaged: '', digupTicketNo: '', facilityOwner: '', 
};
const preExcavationChecklist = {
    id: '',
    editId: '',
    crewForeman: '',
    oneCallTicketNo: '',
    contactNo: '',
    excavationDate: new Date(),
    isVerifiedWorkScope: false,
    isVerifiedWorkBeginDay: false,
    isElectricMarked: false,
    isTelephoneMarked: false,
    isCableTVMarked: false,
    isWaterMarked: false,
    isSewerMarked: false,
    isUnmarkedUtilities: false,
    isGasUnmarked: false,
    isElectricUnmarked: false,
    isTelephoneUnMarked: false,
    isCableTVUnmarked: false,
    isWaterUnmarked: false,
    isSewerUnmarked: false,
    isOneCallNotifiedOnUnmarked: false,
    isSitePhotographed: false,
    isMarkingsPhotographed: false,
    isAdvised18InchHandDigRule: false,
    isAdvisedToTestDugHoles: false,
    attachments: [],
    deletedFiles: [],
};

const utilityDamageReport = {
    id: '',
    editId: '',
    dateOfDamage: new Date(),
    streetAddress: '',
    city: '',
    state: '',
    nearestIntersection: '',
    isElectricAffected: false,
    isNGasAffected: false,
    isSewerAffected: false,
    isWaterAffected: false,
    isTelePhoneAffected: false,
    isCableTVAffected: false,
    isServiceOrDrop: false,
    isMain: false,
    isFiberOptic: false,
    damageDepth: '',
    isOneCallCenterNotified: '',
    locateTicketNumber: '',
    isFacilityMarkVisible: '',
    isFacilityMarkAccurate: '',
    facilityMarkerType: '',
    isPhotosTaken: '',
    typeOfPaintedLocateMarks: '',
    locateMarksDistance: '',
    isTrackHoe: false,
    isBoring: false,
    isAuger: false,
    isTrencher: false,
    isDirectionDrill: false,
    isDrilling: false,
    isHandTools: false,
    isProbingDevice: false,
    isGasPipelineInstalled: false,
    isElectricCable: false,
    isJointTrench: false,
    isTelephoneInstallation: false,
    isCableTVInstallation: false,
    isPolesInstallation: false,
    isAnchorsInstallation: false,
    isOtherWork: false,
    otherWorkType: '',
    isLocationPrivateProp: false,
    isLocationUtilityEasement: false,
    isLocationRoad: false,
    incidentDesc: '',
    attachments: [],
    deletedFiles: [],
    generalForemanName: '',
    generalForemanPhone: '',
    crewForemanName: '',
    crewForemanPhone: '',
    jobNo: '',
    crewNo: '',
};

const toolBoxMeetingForm = {
    id: '', dateOfIncident: format(new Date(), 'yyyy-MM-dd'), timeOfIncident:  format(new Date(), 'HH:mm'), projectName: '', groupName: '', specifiedLocation: '', taskToPerform: '', tsmCoordinater: '', primaryAssemblyPoint: '', secondaryAssemblyPoint: '', nearestMedicalFacility: '', nearestFireExtinguisher: '', nearestFirstAidKit: '', nearestEyeWash: '', cellPhoneUsage: '', isExternalPersonNeed: '', isExternalPersonAffected: '', isExternalPersonBriefed: '', employeesWithinDistance: '', throughCellPhone: '', throughLandLine: '', through2WayWalkie:'', throughOtherCommunication:'', specifyOtherCommunication: '', hotWork: '', lockoutTagout: '', excavation: '', confinedSpace: '', otherTypeOfWork: '', additionalPermitAvailablity: '', hazardousAtmosphere: '', overheadHazards: '', temperatureExtremesHazard: '', chemicalExposureHazard: '', safetySystemsHazard: '', slipsHazard: '', dustyHazard: '', weatherHazard: '', roadwayHazard: '', fallFromHeightHazard: '', heavyLoadsHazard: '', excavationCollapseHazard: '', movingMachinaryHazard: '', noiseHazard: '', adjacentOperationHazard: '', wildLifeHazard: '', suspendedLoadsHazard: '', electricalHazard: '', mobileEquipmentsHazard: '', insectsHazard:'', ignitionSouresHazard: '', pinchPointsHazard: '', overExertionHazard: '', poisonHazard: '', loneWorkerHazard: '', otherHazard: '', specifyOtherHazard: '', drivingHazard: '', faceShield: '', frClothing: '', hearingProtection: '', chainsawChaps: '', otherGeneralPPE: '', specifyOtherGeneralPPE: '', generalPurposeGloves: '', chemicalResistant: '', heatResistant: '', otherGloves: '', specifyOtherGlovesPPE: '', harness: '', lanyard: '', retrievalLine: '', otherFallArrestPPE: '', specifyOtherFallArrestPPE: '', fourGasMonitor: '', h2s: '', o2: '', lel: '', dustMask: '', halfMaskAPR: '', scba: '', otherRespiratorType: '', specifyOtherRespiratorType: '', TSMLeaderName: [], TSMLeaderSignature: '',  emplyeesName: [], /* contractEmplyeesName: [{
        uuid: '',
        name: '', 
    }], */ contractEmplyeesName: [], overallComments: '', worksDetail: [],  attachements: [], deletedFiles: [],
}
const MOCPermanentDeviationForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'), timeOfIncident:  format(new Date(), 'HH:mm'), projectName: '', groupName: '', specifiedLocation: '',originator: '', originatorSignature: '', changeRequestDetails: '', reasonForChange: '', targetDateForChange: '', attachments: [], deletedFiles: [],  safetySupervisor: '', safetySupervisorApprovedDate: '', safetySupervisorSignature: '', safetySupervisorComments: '', productionSupervisor: '', productionSupervisorApprovedDate: '', productionSupervisorSignature:'', productionSupervisorComments: '', safetyManager: '', safetyManagerApprovedDate: '', safetyManagerSignature: '', safetyManagerComments: '', operationsManager: '', operationsManagerApprovedDate: '', operationsManagerSignature: '', operationsManagerApproval: '', operationsManagerComments: '', productionManager: '', productionManagerApprovedDate: '', productionManagerSignature: '', productionManagerApproval: '', }  
    
const MOCTemporaryDeviationForm = {
    dateOfIncident: format(new Date(), 'yyyy-MM-dd'), timeOfIncident:  format(new Date(), 'HH:mm'), projectName: '', groupName: '', specifiedLocation: '', originator: '', originatorSignature: '', changeRequestDuration: '', changeRequestSection: '', changeRequestDetails: '', reasonForChange: '', neededPrecautions: '', safetyManager: '', safetyManagerApprovedDate: '', safetyManagerSignature: '', safetyManagerComments: '', operationsManager: '', operationsManagerApprovedDate: '', operationsManagerSignature: '', operationsManagerComments: '', productionManager: '', productionManagerApprovedDate: '', productionManagerSignature: '', productionManagerApproval: '', generalManager: '', generalManagerApprovedDate: '', generalManagerSignature: '', regionalVicePresident: '', regionalVicePresidentApprovedDate: '', regionalVicePresidentSignature: '', globalOperationsManager: '',globalOperationsManagerApprovedDate: '', globalOperationsManagerSignature: '', overallComments: '', attachments: [], 
}
const walkthroughAssessmentForm = { safetyWalkAssesmentImages: null, supervisoryAssessmentImages: null, closecallImages: null, projectName: '', groupName: '', checklistImages: null, checklistVideos: null, checklistTitle: '', serialNo: '',}

const PTWPermit = {permitType: '', permitNo: '', abrasiveBlasting: '', breakingContainment: '', criticalLift: '', drivingOperations: '', excavationGroundDisturbance: '', highRiskElectrical: '', overTheSideRoad: '', sparkPotential: '', workAtHeights: '', workingOnPressurizedSystems: '', workingInExposedLocations: '', otherCWP: '', mentionOtherCWP: '', plannedDateOfWork: '', plannedTimeOfWork: '', validityPeriod: '', specifiedLocation: '', taskDescription: '', taskArea: '', equipmentId: '', equipmentDescription: '', leadDiscipline: '', iccIsolations: '', rficIsolations: '', personnalIsolations: '', noneIsolations: '', workOrderNumber: '', requestedBy: '', company: '', admosphericTestRecords: '', temporaryDefeat: '', isolationCertificate: '', jsatha: '', liftingPlan: '', msds: '', pidDrawing: '', procedure: '', rescuePlan: '', swpChecklist: '', simopDeviation: '', workAid: '',  PIReviewerName: '', PIReviewerSignature: '', PIReviewerDate: '', PIReviewerTime: '', OIReviewerName: '', OIReviewerSignature: '', OIReviewerDate: '', OIReviewerTime: '', pshDate: [], isPSH: [], pshPI: [], pshOI: [], }
const PTWAtmosphericTest = { areaToBeTested: '', testFrequencyPrierToWorkYes: '', testFrequencyPrierToWorkNo: '', continuousGasTestYes: '', continuousGasTestNo: '', testFrequencyEveryHoursYes: '', testFrequencyEveryHoursNo: '', testFrequencyEveryHours: '', testFrequencyO2: '', testFrequencyLEL: '', testFrequencyH2S: '', testFrequencyCO: '', testFrequencyBenzene: '', testFrequencySO2: '', testFrequencyCO2: '', testFrequencyOther: '', testFrequencyOtherDescribe: '', detecterUsed: [], colibirationDate: [], commentsSpecialPracautions: '', CSEClassLimit: '', ATCSEPreStartResultDate: '', AT1CSEDate: [], ATCSEPreStartResultTime: '', AT1CSETime: [], ATCSEPreStartResultO2: '', AT1CSEO2: [], ATCSEPreStartResultLELGeneralWork: '', AT1CSELELGW: [], ATCSEPreStartResultLELFlammability: '', AT1CSELELF: [], ATCSEPreStartResultCO: '', AT1CSECO: [], ATCSEPreStartResultBenzene: '', AT1CSEBenzene: [], ATCSEPreStartResultSO2: '', AT1CSESO2: [], ATCSEPreStartResultAGTInitials: '', AT1CSEAGTInitials: [], AGTComments: '', }
const PTWIsolationCertificate = { isolation2ICC: '', isolation2RFIC: '', isolation2Electrical: '', isolation2Mechanical: '', isolation2Instrumentation: '', isolation2IsolationCertificateNo: '', isolation2Title: '', isolation2Facility: '', isolation2CreatedBy: '', isolation2Date: '', isolation2Time: '', isolation2DetailsFacility: '', isolatedSystemDescription: '', resonForIsolation: '', zeroEnergyDemonstrationMethod: '', preEstablishedIsolation: '', preEstablishedBlindList: '',

/* isolationDetailEquipmentID: [], isolationDetailDescription: [], isolationDetailIsolationType: [], isolationDetailIsolationMethod: [], isolationDetailComment: [], isolationDetailPointPositionNormal: [], isolationDetailPointPositionIsolatedType: [], isolationDetailInstalledIAAssigned: [],  isolationDetailInstalledIIP: [], isolationDetailRemovedIAAssigned: [], isolationDetailRemovedIAR: [], isolationBlindRegisterDescription: [], isolationBlindRegisterRating: [], isolationBlindRegisterSize: [], isolationBlindRegisterType: [], isolationBlindRegisterDate: [], isolationBlindRegisterIAInitial: [], isolationBlindRegisterVerifierInitial: [], isolationBlindRegisterRemovedDate: [], isolationBlindRegisterRemovedIAInitial: [], isolationBlindRegisterRemovedVerifierInitial: [],  */

isolationPIPlanVerificationName: '', isolationPIPlanVerificationSignature: '', isolationPIPlanVerificationDate: '', isolationPIPlanVerificationTime: '', isolationVerificationPIVerifyName: '', isolationPIIsolationVerificationSignature: '', isolationVerificationPIVerifyDate: '', isolationVerificationPIVerifyTime: '', isolationVerificationOIVerifyName: '', isolationOIIsolationVerificationSignature: '', isolationVerificationOIVerifyDate: '', isolationVerificationOIVerifyTime: '', }
const PTWJSANo = {formJsaNo: '', otherJSAAttachments: [], otherJSAAttachmentsURL: [], }
const PTWChecklist = { SWPChecklistType: '', attributeYes: [],attributeNA: [], checklistComments: [], } 
const PTWTemporaryDefeat = { isolationElectrical: '',isolationMechanical: '', isolationInstrumentationProcess: '', isolationOtherSafety: '', temporaryDefeatInformation: '', reasonDefectSystem: '', riskMitigationHazard: [], riskMitigationControl: [], defeatDurationFromDate: '', defeatDurationFromTime: '', defeatDurationToDate: '', defeatDurationToTime: '', isolationPermitHolderName: '', isolationPIName: '', isolationTemporaryDefeatPISignature: '', isolationDedeatedByName: '', isolationTemporaryDefeatDefeatedBySignature: '', isolationDedeatedDate: '', isolationDedeatedTime: '', longTermDefeatsRequestedByName: '', isolationLongTermDefeatRequestedBySignature: '', longTermDefeatsRequestedDate: '', longTermDefeatsRequestedTime: '', longTermDefeatReason: '', ITDCPIName: '', isolationLongTermDefeatAuthorizationPISignature: '', ITDCPIDate: '', ITDCPIExpiryDate: '', ITDCOIName: '', isolationLongTermDefeatAuthorizationOISignature: '', ITDCOIDate: '', ITDCOIExpiryDate: '', }
const PTWGeneralDocuments = { generalPermitLiftingPlanRefNo: '', generalPermitLiftingPlanAttachments: [], generalPermitLiftingPlanAttachmentsURL: [], generalPermitMSDSRefNo: '', generalPermitMSDSAttachments: [], generalPermitMSDSAttachmentsURL: [], generalPermitPIDRefNo: '', generalPermitPIDAttachments: [], generalPermitPIDAttachmentsURL: [], generalPermitProcedureRefNo: '', generalPermitProcedureAttachments: [], generalPermitProcedureAttachmentsURL: [], generalPermitRescuePlanRefNo: '', generalPermitRescuePlanAttachments: [], generalPermitRescuePlanAttachmentsURL: [], generalPermitSIMOPRefNo: '', generalPermitSIMOPAttachments: [], generalPermitSIMOPAttachmentsURL: [], generalPermitWorkAidRefNo: '', generalPermitWorkAidAttachments: [], generalPermitWorkAidAttachmentsURL: [], }  
const PTWWorkDecleration = { signOffName: [], signOffDate: [], signOffInTime: [], signOffOutTime: [], closeWorkDecleration: '', }
const PTWCloseOut = { pcoWorkCompletedYes: '', pcoWorkCompletedNo: '', PCOPIName: '', permitCloseOutPISignature: '', permitCloseOutFISignature: '', PCOPIDate: '', PCOPITime: '', PCOPIWorkStatusComments: '', /* returnToServiceByName: '', isolationTemporaryDefeatServiceReturnBySignature: '', returnToServiceDate: '', returnToServiceTime: '',  *//* deisolationVerificationPIVerifyName: '', deisolationPIIsolationVerificationSignature: '', deisolationVerificationPIVerifyDate: '', deisolationVerificationPIVerifyTime: '', */ PCOOIIsolationsRemovedYes: '', PCOOIIsolationsRemovedNo: '', PCOOIName: '', permitCloseOutOISignature: '', PCOOIDate: '', PCOOITime: '', closePermitCloseOut: '',  }
const PTWGeneral = {deletedFiles: [], }
const permitToWorkForm = {...PTWPermit, ...PTWAtmosphericTest, ...PTWIsolationCertificate, ...PTWJSANo, ...PTWChecklist, ...PTWTemporaryDefeat, ...PTWGeneralDocuments, ...PTWWorkDecleration, ...PTWCloseOut, ...PTWGeneral};

const permitToWorkForm1 = { permitType: '',  PIReviewerName: '', PIReviewerSignature: '', PIReviewerDate: '', PIReviewerTime: '', OIReviewerName: '', OIReviewerSignature: '', OIReviewerDate: '', OIReviewerTime: '',  
PICSignature: '', AASignature: '', AIASignature: '', PHSignature: '',   AOClosedSignature: '', 
abrasiveBlasting: '', breakingContainment: '', criticalLift: '', drivingOperations: '', excavationGroundDisturbance: '', highRiskElectrical: '', overTheSideRoad: '', sparkPotential: '', workingOnPressurizedSystems:'', workingInExposedLocations:'', workAtHeights: '', otherCWP: '',mentionOtherCWP: '',  plannedDateOfWork: '', plannedTimeOfWork: '', validityPeriod: '', specifiedLocation: '', taskDescription: '', taskArea: '', equipmentId: '', equipmentDescription: '', leadDiscipline: '', /* noOfPeople: '', */ iccIsolations: '', rficIsolations: '', personnalIsolations: '', noneIsolations: '', workOrderNumber: '', requestedBy: '', company: '', pshDate: [], isPSH: [], pshPI: [], pshOI: [],  pcoWorkCompletedYes: '', pcoWorkCompletedNo: '', PCOOIIsolationsRemovedYes: '', PCOOIIsolationsRemovedNo: '',  PCOPIName: '', PCOPISignature:'', PCOPIDate: '', PCOPITime: '', PCOPIWorkStatusComments: '', PCOOIName: '', PCOOIClosedSignature: '', PCOOIDate: '', PCOOITime: '',
admosphericTestRecords: '', temporaryDefeat: '', isolationCertificate: '', jsatha: '', liftingPlan: '', msds: '', pidDrawing: '', procedure: '', rescuePlan: '', swpChecklist: '', simopDeviation: '', workAid: '', 

atmosphericTestType: '', areaToBeTested: '', testFrequencyPrierToWorkYes: '', testFrequencyPrierToWorkNo: '', testFrequencyEveryHours: '', testFrequencyEveryHoursYes: '', testFrequencyEveryHoursNo: '', continuousGasTestYes: '', continuousGasTestNo: '', testFrequencyO2: '',testFrequencyLEL: '', testFrequencyH2S: '', testFrequencyCO: '', testFrequencyBenzene: '', testFrequencySO2: '', testFrequencyCO2: '', testFrequencyOther: '', testFrequencyOtherDescribe: '', detecterUsed: [], colibirationDate: [], commentsSpecialPracautions: '',CSEClassLimit: '', ATCSEPreStartResultDate: '', ATCSEPreStartResultTime: '', ATCSEPreStartResultO2: '', ATCSEPreStartResultLELGeneralWork: '', ATCSEPreStartResultLELFlammability:'',ATCSEPreStartResultTHC:'',ATCSEPreStartResultH2S: '',ATCSEPreStartResultCO:'', ATCSEPreStartResultBenzene:'',ATCSEPreStartResultSO2:'', ATCSEPreStartResultAGTInitials: '', ATCSEPreStartResultVOC: '',     AT1CSEDate: [], AT1CSETime: [], AT1CSEO2: [], AT1CSELELGW: [], AT1CSELELF:[], AT1CSETHC: [],AT1CSEH2S: [],AT1CSECO: [], AT1CSEBenzene:[],AT1CSESO2: [],  AT1CSEAGTInitials: [],  ATAGT: '', AGTName: '',  AtmospericAGTSignature: '', AGTComments: '',
/* formIsolationType: '', */ isolationElectrical: '', isolationMechanical: '', isolationInstrumentationProcess: '', isolationOtherSafety: '', temporaryDefeatInformation: '', /* isApprovedBypassAttached: '',isRiskAssessmentRequired: '',   procedureTitle: '', workPermitNumber: '',*/ reasonDefectSystem: '', /* COPDefeatYes: '', COPDefeatNo: '', COPApprovedYes: '', COPApprovedNo: '', */ riskMitigationHazard: [], riskMitigationControl: [], defeatDurationFromDate: '', defeatDurationFromTime: '', defeatDurationToDate: '', defeatDurationToTime: '', isolationPermitHolderName: '', isolationPIName: '', isolationTemporaryDefeatPISignature: '', isolationDedeatedByName: '', isolationDedeatedBySignature: '', isolationDedeatedDate: '', isolationDedeatedTime: '', /* returnToServiceByName: '', returnToServiceBySignature: '', returnToServiceDate: '', returnToServiceTime: '',  */longTermDefeatsRequestedByName: '', longTermDefeatsRequestedBySignature: '', longTermDefeatsRequestedDate: '', longTermDefeatsRequestedTime: '', longTermDefeatReason: '', ITDCPIName: '', ITDCPISignature: '', ITDCPIDate: '', ITDCPIExpiryDate: '', ITDCOIName: '', ITDCOISignature: '', ITDCOIDate: '', ITDCOIExpiryDate: '', 

/* isolationTemporaryMOCNumber: '', isolationTemporaryMOCAssignedTo: '', isolationTemporaryMOCAssignedDate: '', vibrationMonitoringSE: '', vibrationMonitoringPPME: '', vibrationMonitoringCFS: '', vibrationMonitoringOther: '', vibrationMonitoringMentionOther: '', sealFailureSPCC: '', sealFailureCSPSMI: '', sealFailureOther: '', sealFailureMentionOther:'', engineOverspeedTripSDE: '', engineOverspeedTripCSSMI: '', engineOverspeedTripOther: '', engineOverspeedTripMentionOther: '', limitSwitchedIsolatedDO: '', limitSwitchedIsolatedLP: '', limitSwitchedIsolatedCNLS: '', limitSwitchedIsolatedOther: '', limitSwitchedIsolatedMentionOther: '',
fireProductionSDF: '', fireProductionSHCW: '', fireProductionCAP: '', fireProductionOther: '', fireProductionMentionOther: '', firePumpSDF: '', firePumpSHCW: '', firePumpBackup: '', firePumpCommunicate: '', firePumpOther: '', firePumpMentionOther: '', fireWaterShutDown: '', fireWaterSHCW: '', fireWaterAlternative: '', fireWaterOther: '', fireWaterMentionOther: '', fireEmergencySHCW: '', fireEmergencyAlternative: '', fireEmergencyPortableAir: '', fireEmergencyOther: '', fireEmergencyMentionOther: '',  fireDetectionSHCW: '', fireDetectionCSMI: '', fireDetectionCAP: '', fireDetectionOther:'', fireDetectionMentionOther: '', gasDetectionSHCW: '', gasDetectionCRGD: '', gasDetectionPGD: '', gasDetectionRS: '', gasDetectionCS: '', gasDetectionOther: '', gasDetectionMentionOther: '',  
esdShutDown: '', esdStopHighConsequence: '', esdAdditionalSurveillance:'', esdOther: '', esdMentionOther: '', publicAddressSysAlternativeComm: '', publicAddressSysCommAffected: '', publicAddressSysOther: '', publicAddressSysMentionOther: '', smokeDetectionStopHighConsequence: '', smokeDetectionAdddtionalSurvillance: '', smokeDetectionCommunicateAffected: '', smokeDetectionOther: '', smokeDetectionMentionnOther:'', radarInoperableProvideCoverage: '', radarInoperablePortableRadar: '', radarInoperablePortableCoverageVessel: '', radarInoperableOther: '', radarInoperableMentionOther: '', transportSystemNotifyAffectedParties: '', transportSystemNoNightFlights: '', transportSystemProvideAlternateLighting: '', transportSystemSurveillance:'', transportSystemOther: '', transportSystemMentionOther: '', escapeCraftShutDownSafe: '', escapeCraftStopHighConsequence: '', escapeCraftHelicopter: '', escapeCraftPOB: '', escapeCraftVessalStandby: '', escapeCraftOther: '', escapeCraftMentionOther: '',  EmergencyLightingAlternativeLighting: '', EmergencyLightingCommunicateAffectedPersonnel: '', EmergencyLightingRestrictHighConsequence: '', EmergencyLightingOther: '', EmergencyLightingMentionOther: '', EmergencyEyeWashCommunicateAffected: '', EmergencyEyeWashNoChemical:'', EmergencyEyeWashProvidePortableShower: '', EmergencyEyeWashOther: '', EmergencyEyeWashMentionOther: '', communicationSysShutdownRedundancyEquipment: '', communicationSysShutdownPortableDevices: '', communicationSysShutdownCommunicateAffected: '', communicationSysShutdownOther: '', communicationSysShutdownMentionOther: '', HVACShutdownFacility: '', 
HVACShutdownCommunicatAffected: '', HVACShutdownCommunicatRestrictHighConsequence: '', tankBundingCapacity: '', tankBundingTankEmpty: '', tankBundingOther: '', tankBundingMentionOther: '',  */

analyzerProductMentionOther: '', isolation2ICC: '', isolation2RFIC: '', isolation2Electrical: '', isolation2Mechanical: '', isolation2Instrumentation: '', isolation2IsolationCertificateNo: '', isolation2Title: '', isolation2Facility: '', isolation2CreatedBy: '', isolation2Date: '', isolation2Time: '', isolation2DetailsFacility: '', isolatedSystemDescription: '', resonForIsolation: '', zeroEnergyDemonstrationMethod: '', preEstablishedIsolation: '', preEstablishedBlindList:'', isolationPIPlanVerificationName:'', isolationPIPlanVerificationSignature: '', isolationPIPlanVerificationDate: '', isolationPIPlanVerificationTime: '', isolationVerificationPIVerifyName: '', isolationPIIsolationVerificationSignature: '', isolationVerificationPIVerifyDate: '', isolationVerificationPIVerifyTime: '', isolationVerificationOIVerifyName:'',isolationOIIsolationVerificationSignature: '', isolationVerificationOIVerifyDate: '', isolationVerificationOIVerifyTime:'',  /* deisolationVerificationPIVerifyName: '', deisolationPIIsolationVerificationSignature: '', deisolationVerificationPIVerifyDate: '', deisolationVerificationPIVerifyTime: '',  */

/* isolationDetailEquipmentID: [], isolationDetailDescription: [], isolationDetailIsolationType: [], isolationDetailIsolationMethod: [], isolationDetailComment: [], isolationDetailPointPositionNormal: [], isolationDetailPointPositionIsolatedType: [],   isolationDetailInstalledIAAssigned: [], isolationDetailInstalledIIP: [], isolationDetailRemovedIAAssigned: [], isolationDetailRemovedIAR: [], isolationBlindRegisterDescription: [], isolationBlindRegisterRating: [], isolationBlindRegisterSize: [], isolationBlindRegisterType: [], isolationBlindRegisterDate: [], isolationBlindRegisterIAInitial: [], isolationBlindRegisterVerifierInitial: [], isolationBlindRegisterRemovedDate: [], isolationBlindRegisterRemovedIAInitial: [], isolationBlindRegisterRemovedVerifierInitial: [], */ 

SWPChecklistType: '', checklistDate: '', attributeYes: [],attributeNA: [], checklistComments: [], 

/* generalPermitTemporaryDefeatRefNo: '', generalPermitTemporaryDefeatAttachments: [], generalPermitTemporaryDefeatAttachmentsURL: [],*/

generalPermitLiftingPlanRefNo: '', generalPermitLiftingPlanAttachments: [], generalPermitLiftingPlanAttachmentsURL: [], generalPermitMSDSRefNo: '', generalPermitMSDSAttachments: [], generalPermitMSDSAttachmentsURL: [],
generalPermitPIDRefNo: '', generalPermitPIDAttachments: [], generalPermitPIDAttachmentsURL: [],
generalPermitProcedureRefNo: '', generalPermitProcedureAttachments: [], generalPermitProcedureAttachmentsURL: [],
generalPermitRescuePlanRefNo: '', generalPermitRescuePlanAttachments: [], generalPermitRescuePlanAttachmentsURL: [],generalPermitSIMOPRefNo: '', generalPermitSIMOPAttachments: [], generalPermitSIMOPAttachmentsURL: [],
generalPermitWorkAidRefNo: '', generalPermitWorkAidAttachments: [], generalPermitWorkAidAttachmentsURL: [], 
/* signOffReasonForEntry: '',  */signOffName: [], signOffDate: [], signOffInTime: [], signOffOutTime: [], /* signOffsTimeInEntryAttendantName: '', signOffsTimeOutEntryAttendantName: '', */ closeWorkDecleration: '',
closePermitCloseOut: '', 
deletedFiles: [], 
formJsaNo: '', otherJSAAttachments: [], otherJSAAttachmentsURL: [],
/* workTeamDeclerationDate:[], workTeamDeclerationName: [], workTeamDeclerationSignOn: [], workTeamDeclerationDiffDate: [], workTeamDeclerationSignOff: [],  */ 
}

const hpForm = { name: '', dateOfIncident: format(new Date(), 'yyyy-MM-dd'), groupName: '', projectName: '', comments: '', fitandable: '', planandexecute: '', teamcapable: '', noexternaldistractions: '', }

export const forms = combineForms({ 
    oiForm, officeSafetyForm, personalSafetyForm, jsaForm, incidentNotificationForm, incidentDetails, 
    mechForm, transForm, enggForm, assessmentForm: walkthroughAssessmentForm, checklistFormName, checklistForm, attributeModalForm, attributeFrom, riskAssessmentForm, auditFormName, auditForm, preExcavationChecklist, preExcavationChecklistV2, utilityDamageReport, toolBoxMeetingForm, MOCPermanentDeviationForm, MOCTemporaryDeviationForm, permitToWorkForm, hpForm, covid19AssessmentForm,
    signUpForm, loginForm, forgotPasswordForm, updatePasswordForm, 
    demoForm, concernType, reactSelect, 
    userForm, projectForm, groupForm, goldenRules, referenceForm, feedbackForm, customNotificationForm, followUpForm, closeCallFollowUpForm,
    oiReports, incidentReports,
    monthlyFilter, yearlyFilter, dateRangeFilter,  reportFilterForm, commonFilterForm, locationForm, designationForm, commonForm, concernTypesForm, 
}, 'forms');
