import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { showModal } from '../../actions/modalActions';
import { IHistory } from '../../interfaces';
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { ConfirmationModal } from './ConfirmationModal';
import { PasswordUpdationModal } from './PasswordUpdationModal';
import { capatalize} from '../../utils/generalUtils';
import { UserQRCode } from './UserQRCode'

export interface IDetailsPageProps {
    userInstance: UserModel;
    history?: IHistory;
    userUID: string;
    loggedUser: UserModel;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = () => {
        if (UserModel.list().length <= 1) {
            return getAllUsers();
        }
        return null;
    }

    handleDelete = (id: string) => {
        const userInstance = UserModel.get(id);
        new UserModel(userInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-user', userInstance.props.firstName, userInstance);
    }

    renderProfileRow = (profileKey: string, value: any, key: any) => {
        if (profileKey === 'Groups' || profileKey === 'Teams') {
            if (value.length <= 0) {
                return (
                    <KeyValue key={key} label={profileKey}> NA</KeyValue>
                );
            }
            return (
                <KeyValue key={key} label={profileKey}>
                    {value.map((columnValue: any, i: any) => { 
                        return (
                            <span key={columnValue || ''}>
                                {columnValue.length > 0 ? (columnValue || '')  : 'NA'}{value.length-1!==i && ', '}
                            </span>
                        );
                    })}
                </KeyValue>
            );
        } else if (profileKey === 'Name') {
            return (
                <KeyValue key={key} label={profileKey}>
                    {value.map((name: any, index: React.Key | null | undefined) => {
                        return (
                            <span key={index}>{capatalize((name || ''))}&nbsp;</span>
                        );
                    })}
                </KeyValue>
            );
        }
        return (
            <KeyValue key={key} label={profileKey}>{value}</KeyValue>);
    }

    renderFormCount = () => {
        const { userInstance } = this.props; 
        return (
            <Row className="show-grid form-button">
                <Col xs={6} sm={6} style={{ lineHeight: '2.5' }} className="key">

                    Total Forms Submitted
                </Col>
                <Col xs={6} sm={6} style={{ textAlign: 'left' }} className="value"> 
                    <button style={buttonStyle}
                        /* onClick={() => this.props.history.push
                            (`/user-forms/${userInstance ? userInstance.props.userUID : ''}`)
                        } */
                    >
                        {userInstance ? userInstance.props.numberofForms : ''}
                    </button>
                </Col>
            </Row >
        ); 
    }

    renderProfileQR = () => {
        const { userInstance } = this.props; 
        return (
            <div >
                <Row className="show-grid">
                    <Col xs={6} sm={6} className="key non-print">QR Code</Col>
                    <Col xs={6} sm={6} className="value"> 
                        <UserQRCode user={`${userInstance.props.userUID}/${userInstance.props.company}`}/>
                        {/* <div className='profile-qr'>
                            <span id="print-qr" >
                                <QRCode 
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`${userInstance.props.userUID}/${userInstance.props.company}`}
                                    viewBox={`0 0 256 256`}
                                />
                            </span>
                            <div className="qr-print non-print">
                                <button onClick={() => this.printQR() }>Print QR</button>
                            </div>
                        </div>   */}
                    </Col>
                </Row> 
            </div>
        ); 
    } 
    renderContent = () => {
        const { userInstance } = this.props;
        if (!userInstance) {
            return <Loader />;
        }
        const keys = [ 'Name', 'Email', 'Access Level', 'Job Title', 'Job Description', 'Location', 'Teams', 'Groups'];

        const name = [userInstance.props.firstName, userInstance.props.middleName, userInstance.props.lastName];
        const values = [
            name, userInstance.props.email, userInstance.props.accessLevel, userInstance.props.jobTitle, userInstance.props.jobDesc, userInstance.props.locationName, userInstance.props.projects, userInstance.props.groups
        ];
        return (
            <div className="user-details"> 
                <div className='non-print'>
                    <Title text={`Details of User ${this.props.userInstance.props.firstName}`} /> 
                    <Alert id="user-details" className="danger-alert" />
                    {keys.map((key, index) => {
                        if (UserModel.isDemoUser() && key === 'Email') {
                            return;
                        }
                        return this.renderProfileRow(key, values[index], index + 1);
                    })}
                    {/* <Row className="show-grid">
                        <Col sm={6} className="key">Signature:</Col>
                        <Col sm={6} className="value">{userInstance.props.signatureUrl ?
                            <img height="56px" style={{ border: '1px solid #d2d2d2' }}
                                src={userInstance.props.signatureUrl} /> : <span style={{ color: 'gray' }} >
                                No Signature Uploaded
                                </span>}</Col>
                    </Row> */}
                    {this.renderFormCount()} 
                </div>
                {this.renderProfileQR()} 
                
                <div className='non-print'>
                    {this.renderUpdateUserButton()} 
                </div>
            </div >
        );
    }

    renderUserDetails = () => {
        return (
            <Async
                identifier="UserDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />

        );
    }
    handleUpdatePassword = (id: string) => {
        const userInstance = UserModel.get(id);
        this.setState({
            displayOptions: false
        });
        showModal('update-password', userInstance.props.firstName, userInstance);
    }
    renderUpdateUserButton = () => {
        const { userInstance } = this.props; 
        /* if (loggedUser['accessLevel']!='L1' && loggedUser['accessLevel']!='L2') {
            return;
        } */
        if( (!UserModel.checkUserAccess('user_edit') && !UserModel.checkUserAccess('user_delete') )) {
            return;
        }
        return <div className="show-grid form-button" style={{ textAlign: 'center' }}>
            {userInstance.props.userUID !== this.props.userUID ? <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white',
                width: '201px'
            }}
                onClick={() => userInstance.props.userId && this.handleUpdatePassword(userInstance.props.userId)}
            >
                <i className="fa fa-unlock" aria-hidden="true"></i>&nbsp;
                Update Password
            </Button> : <div></div>}

            <Button style={{
                color: '#FFFFFF',
                border: 'none',
                backgroundColor: '#26a65b',
                width: '127px'
            }}
                onClick={() => this.props.history?.push(`/users/${userInstance.props.userId}/edit`)}
            >
                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                Edit
            </Button>
            {userInstance.props.userUID !== this.props.userUID ? <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white',
                width: '127px'
            }}
                onClick={() => userInstance.props.userId && this.handleDelete(userInstance.props.userId)}
            >
                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;
                Delete
            </Button> : <div></div>}
            
        </div>;
    }

    render() {
        return (
            <BasePage>
                <ConfirmationModal id="delete-user" />
                <PasswordUpdationModal id="update-password" />
                {this.renderUserDetails()}
            </BasePage>
        );
    }
}

const KeyValue = ({ label, children }: any) => {
    return <Row className="show-grid">
        <Col xs={6} sm={6} className="key">{label}</Col>
        <Col xs={6} sm={6} className="value">{children || 'NA'}</Col>
    </Row >;

};

export function mapStateToProps(state: any, ownProps: any) {
    const userInstance = UserModel.get(ownProps.match.params.id, state);
    const userUID = state.login.get('userUID');
    const loggedUser = UserModel.getLoggedUserInstance();
    return {
        userInstance, userUID, loggedUser
    };
}
export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));

const buttonStyle: React.CSSProperties = {
    margin: '0px',
    width: '15%',
    border: 'none',
    background: '#88A4CE',
    color: 'white',
    height: '30px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px grey', cursor: 'default',
};
