import {
    HIDE_IMAGE,
    HIDE_SUB_DROPDOWN_MENU,
    HIDE_WEATHER_MODAL,
    IS_IMAGE_LOADING,
    REMOVE_SUB_DROPDOWN_VALUE,
    RESET_FIELD_EMPTY,
    SAVE_CUSTOM_NOTIFICATION,
    SAVE_FILTER_DATA,
    SAVE_SUB_DROPDOWN_VALUE,
    SAVE_WEATHER_DETAILS,
    SET_FIELD_EMPTY,
    SHOW_IMAGE,
    SHOW_SUB_DROPDOWN_MENU,
    SHOW_WEATHER_MODAL,
    SAVE_REFERENCE_ITEMS, 
    SAVE_LIST_COUNT, SAVE_HOME_COUNTS, SAVE_AXIOS_PREVIOUS_TOKEN
} from '../constants/actions';
import { ICustomNotification } from '../interfaces';
import { dispatch } from '../utils/generalUtils';

export function saveReferenceFiles(referenceFiles: any) {
    return dispatch({
        type: SAVE_REFERENCE_ITEMS,
        referenceFiles
    });
}

export function shoCarousel(imageURL: string) {
    return dispatch({
        type: SHOW_IMAGE,
        imageURL
    });
}

export function hideImage() {
    return dispatch({
        type: HIDE_IMAGE,
    });
}

export function isImageLoading(isLoading: boolean) {
    return dispatch({
        type: IS_IMAGE_LOADING,
        isLoading
    });
}

//export function setEmptyField(fieldModelName: string) {
export function setEmptyField(fieldModelName: any) {
    return dispatch({
        type: SET_FIELD_EMPTY,
        fieldModelName
    });
}

export function resetEmptyField() {
    return dispatch({
        type: RESET_FIELD_EMPTY,
    });
}

export function saveCustomNotification(customNotification: ICustomNotification) {
    return dispatch({
        type: SAVE_CUSTOM_NOTIFICATION,
        customNotification
    });
}

export function showSubDropdownMenuOf(key: string) {
    return dispatch({
        type: SHOW_SUB_DROPDOWN_MENU,
        key
    });
}

export function hideSubDropdownMenu() {
    return dispatch({
        type: HIDE_SUB_DROPDOWN_MENU,
    });
}

export function saveSubDropdownValue(value: string) {
    return dispatch({
        type: SAVE_SUB_DROPDOWN_VALUE,
        value
    });
}

export function removeSubDropdownValue(value: string) {
    return dispatch({
        type: REMOVE_SUB_DROPDOWN_VALUE,
        value
    });
}

export function saveFilterData(filterType: string, yyyy: string, mm: string = 'na') {
    return dispatch({
        type: SAVE_FILTER_DATA,
        yyyy, mm, filterType
    });
}

export function saveWeatherDetails(weatherDetails: any) {
    return dispatch({
        type: SAVE_WEATHER_DETAILS,
        weatherDetails
    });
}

export function showWeatherModal() {
    return dispatch({
        type: SHOW_WEATHER_MODAL
    });
}

export function hideWeatherModal() {
    return dispatch({
        type: HIDE_WEATHER_MODAL
    });
}

/* export function saveOIFormCount(totalCount: number) {
    return dispatch({
        type: SAVE_OI_FORM_COUNT,
        totalCount
    });
} */

/* export function saveOfficeSafetyFormCount(totalCount: number) {
    return dispatch({
        type: SAVE_OFFICE_SAFETY_FORM_COUNT,
        totalCount
    });
} */

export function saveListCount(totalCount: number) {
    return dispatch({
        type: SAVE_LIST_COUNT,
        totalCount
    });
}
export function saveHomeData(groupCount: number, projectCount: number, userCount: number) {
    return dispatch({
        type: SAVE_HOME_COUNTS,
        groupCount, projectCount, userCount
    });
}
export function saveAxiosPreviousToken(token: any) {
    return dispatch({
        type: SAVE_AXIOS_PREVIOUS_TOKEN,
        token
    });
}
 