import axios from 'axios';
import { actions } from 'react-redux-form';
import {pushAlert, removeAlert, setTimeOutID} from '../../src/actions/alertActions';
import {setSuccess} from '../actions/loadingActions';
import {logout} from '../actions/loginActions';
import {isImageLoading, setEmptyField} from '../actions/miscellaneousActions';
import {removeInstance} from '../actions/modelActions';
import {IAction, IAnswer, IChartValue, IHistory, IThunkAction} from '../interfaces';
import {IFollowUpModelProps} from '../model/FollowUpModel';
import {IJobSafetyStep} from '../model/JSAModel';
import {getUserInfo, removeUserInfo} from '../services/loginService';
import {store} from '../store';
import {FATick} from '../components/reusableComponents/FATIck';
import {Row, Col, Table} from 'react-bootstrap';
import {parse, stringify} from 'querystring';
import {format} from 'date-fns';
import {Img} from '../components/reusableComponents/ImageComponents/Img';
import {v1 as uuidv1 } from 'uuid';

declare var require: any;
require('pdfmake/build/pdfmake.js');
const AWS = require('aws-sdk');
/* const uuidv1 = require('uuid/v1'); */ 

/* export function environment() {
    return process.env.NODE_ENV;

} */

export function dispatch<T extends IAction>(action: T | IThunkAction) {
    /* if (environment().indexOf('test') > -1) {
        throw new Error('Dispatch function needs to be mocked for the test environment');
    } */

    if ((action as IAction).type) {
        return store.dispatch((action as T));
    }

    //return store.dispatch<{}, {}>(action as IThunkAction);
    return store.dispatch<any>(action as IThunkAction);
}

export function isEmpty(obj: Object): boolean {
    return !obj || Object.keys(obj).length === 0;
}

export function showAlert(text: string, id: string, type: string, cb?: Function, autoClose: boolean = true, timeOut?: number) {
    pushAlert(text, id, type);
    timeOut = timeOut ? timeOut : 5000;
    if (autoClose) {
        let timeOutID = setTimeout(() => {
            removeAlert(id);
            if (cb instanceof Function) {
                cb();
            }
        }, timeOut);
        setTimeOutID(timeOutID.toString());
    }
}

export function clearSetTimeout(id: string, cb: Function) {
    let timeOutID = store.getState().alert.get('timeOutID');
    clearTimeout(timeOutID);
    cb();
    setTimeout(function () {
        removeAlert(id);
    }, 100);
}

export function redirectToHome(cb: Function) {
    if (cb instanceof Function) {
        cb();
    }
}

export function keys(obj: Object, callback: Function): void {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            callback(key);
        }
    }
}

export function contains<T>(arr: string[], item: string) {
    return arr.indexOf(item) > -1;
}

export function capatalize(item: any) { 
    if (!item) {
        return '';
    }
    return item.charAt(0).toUpperCase() + item.slice(1);
}

//String.prototype.capatalize = capatalize;

export function constructAnswers(answerData: any) {
    let quesNum: number = 1;
    const answers: IAnswer[] = [];
    for (let key in answerData) {
        if (answerData.hasOwnProperty(key)) {
            answers.push(
                {
                    'quesType': key,
                    'quesNum': quesNum++,
                    'answer': answerData[key] === undefined || answerData[key] === null || answerData[key] === '' ? null : answerData[key]
                }
            );
        }
    }
    return answers;
}

export function constructLongIncidentAnswers(answerData: any) {
    let quesNum: number = 1;
    const answers: IAnswer[] = [];
    for (let key in answerData) {
        if (key === 'projectName' || key === 'groupName' || key === 'incidentReportImages' || key === 'incidentReportVideos'
            || key === 'associatedFormId' || key === 'specifiedLocation') {
            continue;
        }
        if (key === 'projectOIMSDetails') {
            answerData[key].forEach((ans: { [x: string]: any; }) => {
                answers.push(
                    {
                        'quesType': 'projectOIMSDetailsProjectOIMSSystem',
                        'quesNum': quesNum++,
                        'answer': ans['projectOIMSSystem']
                    },
                    {
                        'quesType': 'projectOIMSDetailsOIMSSystemDate',
                        'quesNum': quesNum++,
                        'answer': ans['OIMSSystemDate']
                    }
                );
            });
        } else if (key === 'incidentInvestigation') {
            /* answerData[key].forEach(ans => {
                answers.push(
                    {
                        'quesType': 'incidentInvestigationActionItem',
                        'quesNum': quesNum++,
                        'answer': ans['actionItems']
                    },
                    {
                        'quesType': 'incidentInvestigationPartyResponsible',
                        'quesNum': quesNum++,
                        'answer': ans['partyResponsible']
                    },
                    {
                        'quesType': 'incidentInvestigationTargetDate',
                        'quesNum': quesNum++,
                        'answer': ans['targetDate']
                    }
                );
            }); */
        } /* else if (key === 'preliminaryReport' && answerData[key]) {
            answers.push(
                {
                    'quesType': 'report',
                    'quesNum': quesNum++,
                    'answer': 'Preliminary Report'
                }
            );
            continue;
        } else if (key === 'finalReport' && answerData[key]) {
            answers.push(
                {
                    'quesType': 'report',
                    'quesNum': quesNum++,
                    'answer': 'Final Report'
                }
            );
            continue;
        } */ else {
            answers.push(
                {
                    'quesType': key,
                    'quesNum': quesNum++,
                    'answer': answerData[key] || null
                }
            );
        }
    }
    return answers;
}

export function constructActionItems(incidentInvestigation: IFollowUpModelProps[]) {
    let followups: { action: string; responsible: string[]; target: string; followUpId: string; }[] = [];
    incidentInvestigation.forEach(item => {
        if (item.actionItems) {
            followups.push({
                action: item.actionItems,
                responsible: item.partyResponsible.split(','),
                target: item.targetDate,
                followUpId: item.UUID || ''
            });
        }
    });
    return followups;
}

export function constructJSAAnswers(answerData: IJobSafetyStep[], location: string, typeOfWork: string,
                                    jsaN: string, workToPerform: string, companyName: string, locationName: string, dateOfAnalysis: string, approval?: string): IAnswer[] {
    let quesNum = 1;
    const answers: IAnswer[] = [];
    answers.push(
        {
            'quesType': 'companyName',
            'quesNum': 0,
            'answer': companyName,
        },
        {
            'quesType': 'location',
            'quesNum': 0,
            'answer': location,
        },
        {
            'quesType': 'locationName',
            'quesNum': 0,
            'answer': locationName,
        },
        {
            'quesType': 'typeOfWork',
            'quesNum': 0,
            'answer': typeOfWork,
        },
        {
            'quesType': 'jsaNum',
            'quesNum': 0,
            'answer': jsaN,
        },
        {
            'quesType': 'workToPerform',
            'quesNum': 0,
            'answer': workToPerform,
        },
        {
            'quesType': 'dateOfAnalysis',
            'quesNum': 0,
            'answer': dateOfAnalysis,
        },
        {
            'quesType': 'approval',
            'quesNum': 0,
            'answer': approval || '',
        }
    );
    answerData.forEach((answer: any, index: any) => {
        if (!answer.stepDesc || !answer.controls || !answer.hazards) {
            return answer;
        }
        for (let key in answer) {
            answers.push({
                'quesType': key,
                'quesNum': quesNum,
                'answer': answer[key] || ''
            });
        }
        quesNum++;
    });
    return answers;
}

export function isLoggedIn() {
    if (!getUserInfo().isLoggedIn) {
        return;
    }
    return true;
}

export function checkIfAllZero(obj: IChartValue[]) { 
    return obj.some(object => {
        if (object.value !== 0) {
            return true;
        } else {
            return false;
        }
    });
}

export function isArrayEmpty(arr: string[]) {
    if (arr.length <= 0) {
        return true;
    } 
    return arr.some(value => {
        if (!value) { 
            return true;
        } else {
            return false;
        }
    });
}

export function isArrayEmptyAndAlert(arr: any) {
    let formEmpty = false;
    for (let index in arr[Object.keys(arr)[0]]) {
        for (let subIndex in arr) {
            let value = arr[subIndex][index];
            if ((value === '' || value === undefined || value.toString().length <= 0)) {
                setEmptyField(`${subIndex}[${index}]`);
                formEmpty = true;
                return formEmpty;
            }

        }
    }
    return formEmpty;
}

export function isArrayOfObjEmpty(modelLabel: string, arr: any) {
    // const emptylabel=arr.map((data, index) => (
    //     Object.keys(data)
    // )); 
    let formEmpty = false;
    arr.forEach((data: { [x: string]: any; }, index: any) => {
        for (let key in data) {
            if (data[key] === '' || (key.toLocaleLowerCase().indexOf('date') > -1 && !checkValidDate(data[key]))) {
                setEmptyField(`${modelLabel}[${index}].${key}`);
                formEmpty = true;
                return;
            }
        }
    });
    return formEmpty;
}

export function checkValidDate(date: any) {
    return date !== '1970-01-01' && (Date.parse(date)) && true;
}

export function isFormEmpty(submittedValues: { [x: string]: any; }, ignoreList?: Array<string>, emptyFields?: any) {
    let formEmpty = false;
    const ignoredKeyList = ignoreList || []; 
    for (let key in submittedValues) { 
        if (ignoredKeyList.indexOf(key) < 0 && (submittedValues[key] === null || submittedValues[key] === '' || submittedValues[key] === undefined || submittedValues[key].toString().length <= 0 || submittedValues[key].toString().trim().length <= 0 || (key.toLocaleLowerCase().indexOf('date') > -1 && !checkValidDate(submittedValues[key])))) { //|| (submittedValues[key] instanceof Array && isArrayEmpty(submittedValues[key]) )3
            console.log(emptyFields) 
            emptyFields?.push('.'+key);
            formEmpty = true;
            //break;
        } else {
            formEmpty = false;
        }
    }   
    console.log(emptyFields)
    setEmptyField(emptyFields);
    return formEmpty;
}

export function isPasswordValid(value: string) {
    //eslint-disable-next-line
    const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
    return strongRegex.test(value);
}

export function logoutUser(resource: any, userId: any, cb: Function) {
    logout();
    removeUserInfo();
    removeInstance(`${resource + userId}`);
    // /**
    //  * Requiring the UserModel here to avoid the circular dependency issue facing while adding the group form.
    //  */
    // const UserModel = require('../model/UserModel');
    // new UserModel(userId).$delete();
    cb();
}

export function convertToLabel(answers: Object[]) {
    const labels: any[] = [];
    answers.forEach((val: any) => {
        labels.push(val.quesType.capatalize().replace(/([A-Z])/g, ' $1'));
    });
    return labels;
}

export function convertTime(time: any) { 
    if (time.split(':')[0] < 12) {
        return '0' + time.split(':')[0] % 12 + ':' +
            time.split(':')[1] + ' AM';
    } else if (time.split(':')[0] === '00') {
        return '00' + ':' + time.split(':')[1] + ' AM';
    } else if (time.split(':') === 12) {
        return '12' + time.split(':')[1] + 'PM';
    }
    return time.split(':')[0] % 12 > 10 ? time.split(':')[0] % 12 + ':' +
        time.split(':')[1] + ' PM' : '0' + time.split(':')[0] % 12 + ':' +
        time.split(':')[1] + ' PM';
}
 
export function getFormattedDate(date = new Date()) {
    date.setDate(date.getDate() + 1);
    return format(date, 'yyyy-MM-dd');
}

export function convertToDateFormat(date: any, dateFormat: any = 'MM/dd/yyyy') {
    return format(new Date(date + 'T17:00:00.00Z'), dateFormat);
}

export function printDetails(ref: any, fileName: string, isLong: boolean = false) {
    //const element = document.getElementById('print'); 
    // domtoimage.toPng(ref, {
    //     height: ref.clientHeight,
    //     width: 600,
    //     style: {
    //         transform: 'unset',
    //         left: '0%',
    //         margin: 'unset',
    //         backgroundColor: 'white',
    //         maxHeight: '100%'
    //     },
    // })
    //     .then(function (dataURL) { 
    //         const docDefinition = {
    //             pageSize: { width: 600, height: ref.clientHeight },
    //             content: [
    //                 {
    //                     image: dataURL,
    //                     fit: [ref.clientHeight, (ref.clientHeight - 200)],
    //                 }
    //             ]
    //         };
    //         pdfmake.createPdf(docDefinition).download(fileName);
    //         setSuccess('PrintPreviewLoader');
    //         hidePrintPreviewModal();
    //     }).catch(function (error) { 
    //     });
    setSuccess('PrintPreviewLoader');
    // hidePrintPreviewModal();
}

declare const Buffer: any;

export function getBase64(url: string) {
    return axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
    }).then(response => new Buffer(response.data, 'binary').toString('base64'));
}

export function compressBase64Image(base64String: string, callback: (arg0: string) => void) {
    isImageLoading(true);
    const canvas = document.createElement('canvas');
    let image64 = '';
    const canvasContext = canvas.getContext('2d');
    canvas.height = 75;
    canvas.width = 90;
    const image = new Image();
    image.src = base64String;
    if (canvasContext) {
        image.onload = (e) => {
            canvasContext.drawImage(image, 0, 0, image.width, image.height,
                0, 0, canvas.width, canvas.height);
            image64 = canvas.toDataURL();
            callback(image64);
        };
    }
} 
/* class Modal extends ReactOverlayModal {
    focus = () => { };
} 
export default Modal as any; */

export function getLegendNamesFromObj(object: { hasOwnProperty: (arg0: string) => any; }) {
    const legends:Array<any> = [];
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            legends.push(key);
        }
    }
    return legends;
}

export function marshallPieChartData(object: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }) {
    const data:Array<any> = [];
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            data.push({
                name: key, value: object[key]
            });
        }
    }
    return data;
}

export function getCurrentUserCompanyName() {
    return getUserInfo().company;
}

export const KeyValue = ({label, children}: any) => {
    return <Row className="show-grid">
        <Col xs={6}
             className="details-label"
             style={{textAlign: 'right'}} sm={6}>{label}:</Col>
        <Col className="details-value"
             sm={6} xs={6} id="detailsChild">{children || 'N/A'}</Col>
    </Row>;
};

export function getQueryParams(search: string) {
    return parse(search);
}

export function setFilters(filters: any, history: IHistory) {
    const {location: {pathname},} = history;
    history.push(pathname + `?${stringify(filters)}`)
    //history.push({ pathname, search: stringify(filters) });
}

export function imageExists(image_url: string) {
    var http = new XMLHttpRequest();
    http.open('GET', image_url, false);
    http.send();
    return http.status !== 404;
}

export function getPageItemNumber(index: number, currentPage: number = 1) {
    return index + 1 + (currentPage ? (currentPage - 1) * 20 : 0);
}

export function sortBy(data: any, type: string = 'alpha') {
    switch (type) {
        case 'alpha':
            return data.sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));
    }
}

export function substring(str: string, length: number, needETC: boolean = true) {
    return str.length > length ? str.substring(0, length) + (needETC ? ' ...' : '') : str;
}

export function uuid() {
    return uuidv1();
}

export function renderCovid19Result(result: string | undefined, type = 'report') {
    if (type === 'report')
        return result === '1' ? <FATick title="Result Negative" style={{backgroundColor: '#26a65b', margin: 'auto'}}
                                        className="fa-times"/> :
            <FATick title="Result Positive" style={{backgroundColor: '#ff7a7a', margin: 'auto'}}/>;
    else
        return result === '1' ? <span style={{
            fontSize: '14px',
            backgroundColor: '#5fab5f',
            padding: '5px 10px 5px 10px',
            borderRadius: '10px',
            color: 'white'
        }}>Safe</span> : <span style={{
            fontSize: '14px',
            backgroundColor: '#e36868',
            padding: '5px 10px 5px 10px',
            borderRadius: '10px',
            color: 'white'
        }}>Need Medical Attention</span>;
}

export function renderCovid19QuestionResult(result: string | undefined, type = 'risk') {
    let background = type === 'safe' ? '#26a65b' : '#ff7a7a';
    return result === '1' ? <FATick style={{backgroundColor: background}} title="Result Positive"/> : '';
}

export function convertText(text: string | number, lan = getUserInfo().language) {
    let content: any = {
        covidHeading: {eng: 'COVID-19\n Self Assessment', spa: 'AUTO EVALUACIÓN\n COVID-19'},
        selfAssessment: {eng: 'Self Assessment', spa: 'Auto evaluación'},
        assessmentComplete: {eng: 'COVID-19 Self Assessment Complete!', spa: '¡Autoevaluación COVID-19 completada!'},
        date: {eng: 'Date', spa: 'FECHA'},
        time: {eng: 'Time', spa: 'HORA'},
        proceedWork: {eng: 'Proceed to Work', spa: 'Proceda al trabajo'},
        assessmentSuggesstion: {
            eng: 'Please continue to wear your face mask, wash hands, and practice social distancing.',
            spa: 'Continúe usando su mascarilla, lávese las manos y practique el distanciamiento social.'
        },
        close: {eng: 'CLOSE', spa: 'CERRAR'},
        basedOnSelection: {
            eng: 'Based on your selection(s), you are encouraged to:',
            spa: 'Según su(s) selección(es), se le anima a:'
        },
        stayHome: {
            eng: 'STAY AT HOME\n and/or\n  SEEK MEDICAL \n ATTENTION',
            spa: 'QUÉDASE EN CASA\n y / o \n BUSQUE ATENCIÓN \n  MÉDICA'
        },
        contactManager: {
            eng: 'You should contact your Manager for next step actions',
            spa: 'Debe comunicarse con su gerente para conocer los próximos pasos'
        },
        source: {eng: 'Source', spa: 'Fuente'},
        guidelines: {eng: 'guidelines', spa: 'pautas'},
        thanksForAssessment: {
            eng: 'Thank you for prioritizing health & safety!',
            spa: '¡Gracias por dar prioridad a la salud y la seguridad!'
        },
        ques1: {
            eng: '1. Do you currently have any of the following COVID-19 symptoms? (Select all that apply):',
            spa: '1. ¿Tiene alguno de los siguientes síntomas de COVID-19? (Seleccione todas las que correspondan): '
        },
        ques1Option1: {eng: 'Fever of 100.4 F or over', spa: 'Fiebre de 100.4 F o más'},
        ques1Option2: {eng: 'New or worsening cough', spa: 'Tos nueva o que empeora'},
        ques1Option3: {
            eng: 'Shortness of breath or difficulty breathing',
            spa: 'Falta de aire o dificultad para respirar'
        },
        ques1Option4: {eng: 'New loss of taste or smell', spa: 'Nueva pérdida del gusto u olfato'},
        ques1Option5: {eng: 'Sore throat', spa: 'Dolor de garganta'},
        ques1Option6: {eng: 'Constant pain in chest', spa: 'Dolor constante en el pecho'},
        ques1Option7: {eng: 'None of the above', spa: 'Ninguna de las anteriores'},
        ques1Error: {eng: 'Select at least one checkbox', spa: 'Seleccione al menos una casilla de verificación'},
        ques2: {
            eng: '2.  In the last 2 weeks, have you had contact with anyone diagnosed with COVID-19?',
            spa: '2. En las últimas 2 semanas, ¿ha tenido contacto con alguien diagnosticado con COVID-19? '
        },
        ques2Option1: {eng: 'Yes', spa: 'Si'},
        ques2Option2: {eng: 'No', spa: 'No'},
        ques2Error: {
            eng: 'Select at least one checkbox for question 2',
            spa: 'Seleccione al menos una casilla de verificación para la pregunta 2'
        },
        ques3: {
            eng: '3. In the last 2 weeks, have you had any of the following exposures? (Select all that apply)',
            spa: '3. En las últimas 2 semanas, ¿estuvo expuesto a alguno de los siguientes? (Seleccione todas las que correspondan)'
        },
        ques3Option1: {eng: 'International travel', spa: 'Viajes internacionales'},
        ques3Option2: {
            eng: 'Visited an area where COVID-19 was prevalent',
            spa: 'Visitó un área donde prevalecía COVID-19'
        },
        ques3Option3: {
            eng: 'Public gathering for prolonged amount of time with no PPE',
            spa: 'Reunión pública durante un período prolongado sin EPP'
        },
        ques3Option4: {eng: 'None of the above', spa: 'Ninguna de las anteriores'},
        ques3Error: {
            eng: 'Select at least one checkbox for question 3',
            spa: 'Seleccione al menos una casilla de verificación para la pregunta 3'
        },
        next: {eng: 'Next', spa: 'Próximo'},
        previous: {eng: 'Previous', spa: 'ANTERIOR'},
        submit: {eng: 'Submit', spa: 'ENVIAR'},
        confirmAssessmentTitle: {eng: 'Confirm Assessment', spa: 'Confirmar evaluación'},
        confirmAssessmentQues: {
            eng: 'Are you sure, you want to submit your COVID assessment answer(s) with a risk factor?',
            spa: '¿Está seguro de que desea enviar las respuestas de la evaluación COVID con un factor de riesgo?'
        },
        yes: {eng: 'Yes', spa: 'si'},
        no: {eng: 'No', spa: 'No'},
        submittingAssessment: {
            eng: 'Submitting assessment. Please wait..',
            spa: 'Envío de evaluación. Por favor espera..'
        },
        sorryWentWrong: {
            eng: 'Sorry, Something went wrong. Please try again sometime...',
            spa: 'Perdón, algo salió mal. Vuelve a intentarlo en algún momento ...'
        },
        fetchData: {eng: 'Fetching data. Please wait..', spa: 'Recuperacion de datos. Por favor espera..'},
        alreadyAssessed: {
            eng: 'You have been already self assessed COVID-19 for TODAY..\n Thank you for prioritizing health & safety!',
            spa: 'Ya se ha autoevaluado COVID-19 HOY .. \n ¡Gracias por priorizar la salud y la seguridad!'
        },
        appliedOnlyTo: {
            eng: 'Español is only apply to COVID-19 Self-Assessment',
            spa: 'El español solo se aplica a la autoevaluación COVID-19'
        }

    };
    return content[text][lan] || content[text]['eng'] || text
}

export function base64ToFile(dataURL: any, fileName: string) {  
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    return (fetch(dataURL)
        .then(function (result) {
            return result.arrayBuffer();
        })).then(function (buffer) {
        return new File([buffer], fileName, {type: mime});
    });
}

export function getSignatureData(file: any, quesType: string) { 
    /* const name = new Date().getTime().toString(); */
    const name = uuid();
    return {'name': `${name}.png`, 'quesType': quesType, 'file': file}
}

export async function uploadFile(file: { name: string; type: any; }, name: any, key: any) {
    AWS.config.update({
        'accessKeyId': 'AKIA47N26QZ4CGZ6766P',
        'secretAccessKey': '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', 
    });
    const fileExtension = file.name.split('.')[file.name.split('.').length - 1];

    AWS.config.region = 'us-west-2';
    const s3 = new AWS.S3();

    const myBucketName = 'safeconnect-beta';
    const params = {
        Bucket: myBucketName, 
        Key: `${key}/${name}.${fileExtension}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };
    return s3.putObject(params, function (err: any, data: any) {
        if (err) {
            throw err;
        } else {
            return true;
        }
    });
}

export function ValidURL(str: string) {
    //eslint-disable-next-line
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
        return false;
    } else {
        return true;
    }
}

export function eventCategorization() {
    let content: any = {
        1: ['I', 'Loss of life, loss of critical propreitary information, loss of critical assets, significant impairment of mission, loss of system'],
        2: ['II', 'Severe injury to employeeor other individual, loss of propreitary information and physical equipment resulting from undetected or unauthorized acess, unacceptable mission delays, unacceptable system and operations disruption.'],
        3: ['III', 'Minor injury not requiring hospitalization, undetected or delay in the detection of unauthorized entry resulting in limited acess to assets or sensitive materials, no mission impairment, minor system and operations disruption.'],
        4: ['IV', 'Less then minor injury, undetected or delay in the detection of unauthorized entry with no asset loss or acess to sensitive materials, no system or operations disruption']
    }
    return content;
}

export function probabilityCategorization() {
    let content: any = {
        1: ['A', 'Frequent - Possibility of repeated incidents.'],
        2: ['B', 'Probable - Possibility of isolated incidents.'],
        3: ['C', 'Occasional - Possibility of occuring sometimes.'],
        4: ['D', 'Remote - Most likely to occur.'],
        5: ['E', 'Improbable - Practically impossible.'],
    };
    return content;
}


export function ssriResultTable(averageEventCategory: any, averageProbabilityCategory: any) {
    return (
        <><Row className="analysis-result-table-content">
            <Table responsive bordered hover size="sm" className="analysis-result-table">
                <thead>
                <tr>
                    <th colSpan={1}>PROBABILITY OF OCCURENCE</th>
                    <th colSpan={4}>
                        <tr>
                            <th colSpan={4}>RISK SEVERITY CATEGORIES</th>
                        </tr>
                        <tr className="risk-severity-categories">
                            <th>I</th>
                            <th>II</th>
                            <th>III</th>
                            <th>IV</th>
                        </tr>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>(A) FREQUENT</td>
                    <td className={`res1 ${averageEventCategory === 1 && averageProbabilityCategory === 1 ? 'highlight' : ''}`}>1</td>
                    <td className={`res1 ${averageEventCategory === 2 && averageProbabilityCategory === 1 ? 'highlight' : ''}`}>1</td>
                    <td className={`res1 ${averageEventCategory === 3 && averageProbabilityCategory === 1 ? 'highlight' : ''}`}>1</td>
                    <td className={`res3 ${averageEventCategory === 4 && averageProbabilityCategory === 1 ? 'highlight' : ''}`}>3</td>
                </tr>
                <tr>
                    <td>(B) PROBABLE</td>
                    <td className={`res1 ${averageEventCategory === 1 && averageProbabilityCategory === 2 ? 'highlight' : ''}`}>1</td>
                    <td className={`res1 ${averageEventCategory === 2 && averageProbabilityCategory === 2 ? 'highlight' : ''}`}>1</td>
                    <td className={`res2 ${averageEventCategory === 3 && averageProbabilityCategory === 2 ? 'highlight' : ''}`}>2</td>
                    <td className={`res3 ${averageEventCategory === 4 && averageProbabilityCategory === 2 ? 'highlight' : ''}`}>3</td>
                </tr>
                <tr>
                    <td>(C) OCCASIONAL</td>
                    <td className={`res1 ${averageEventCategory === 1 && averageProbabilityCategory === 3 ? 'highlight' : ''}`}>1</td>
                    <td className={`res2 ${averageEventCategory === 2 && averageProbabilityCategory === 3 ? 'highlight' : ''}`}>2</td>
                    <td className={`res2 ${averageEventCategory === 3 && averageProbabilityCategory === 3 ? 'highlight' : ''}`}>2</td>
                    <td className={`res4 ${averageEventCategory === 4 && averageProbabilityCategory === 3 ? 'highlight' : ''}`}>4</td>
                </tr>
                <tr>
                    <td>(D) REMOTE</td>
                    <td className={`res2 ${averageEventCategory === 1 && averageProbabilityCategory === 4 ? 'highlight' : ''}`}>2</td>
                    <td className={`res2 ${averageEventCategory === 2 && averageProbabilityCategory === 4 ? 'highlight' : ''}`}>2</td>
                    <td className={`res3 ${averageEventCategory === 3 && averageProbabilityCategory === 4 ? 'highlight' : ''}`}>3</td>
                    <td className={`res4 ${averageEventCategory === 4 && averageProbabilityCategory === 4 ? 'highlight' : ''}`}>4</td>
                </tr>
                <tr>
                    <td>(E) IMPROBABLE</td>
                    <td className={`res3 ${averageEventCategory === 1 && averageProbabilityCategory === 5 ? 'highlight' : ''}`}>3</td>
                    <td className={`res3 ${averageEventCategory === 2 && averageProbabilityCategory === 5 ? 'highlight' : ''}`}>3</td>
                    <td className={`res3 ${averageEventCategory === 3 && averageProbabilityCategory === 5 ? 'highlight' : ''}`}>3</td>
                    <td className={`res4 ${averageEventCategory === 4 && averageProbabilityCategory === 5 ? 'highlight' : ''}`}>4</td>
                </tr>
                </tbody>
            </Table>
        </Row></>
    );
}

export function getEventCategory(category: any) {
    let eventCategory: any = [];
    switch (true) {
        case (category === 4 || category === '4'):
            eventCategory = [4, 'IV'];
            break;
        case (category === 3 || category === '3'):
            eventCategory = [3, 'III'];
            break;
        case (category === 2 || category === '2'):
            eventCategory = [2, 'II'];
            break;
        case (category === 1 || category === '1'):
            eventCategory = [1, 'I'];
            break;
        default:
            eventCategory = [0, '0'];
            break;
    }
    return eventCategory;
}

export function getProbabilityCategory(category: any) {
    //category = isNaN(category) || category=='0'?category:category.toLowerCase();
    let probabilityCategory: any = [];
    switch (true) {
        case (category === 'a' || category === 'A' || category === 1):
            probabilityCategory = [1, 'a', 'A'];
            break;
        case (category === 'b' || category === 'B' || category === 2):
            probabilityCategory = [2, 'b', 'B'];
            break;
        case (category === 'c' || category === 'C' || category === 3):
            probabilityCategory = [3, 'c', 'C'];
            break;
        case (category === 'd' || category === 'D' || category === 4):
            probabilityCategory = [4, 'd', 'D'];
            break;
        case (category === 'e' || category === 'E' || category === 5):
            probabilityCategory = [5, 'e', 'E'];
            break;
        default:
            probabilityCategory = [0, '0', '0'];
            break;
    }
    return probabilityCategory;
}

export function backToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export function printLogos() {
    let companyLogo = getUserInfo().companyLogo;
    return (
        <div className="print-logo row" style={{marginBottom: '10px'}}>
            <div className="col-sm-6">
                {companyLogo && <Img
                    key={'companyLogo'}
                    style={{padding: '5px'}}
                    width="90px"
                    height="75px"
                    //src={`https://safeconnect-beta.s3.us-west-2.amazonaws.com/static-files/DART.png`}
                    src={companyLogo}
                />}
            </div>
            <div className="col-sm-6" style={{textAlign: 'right'}}>
                <Img
                    key={'companyLogo'}
                    style={{padding: '5px'}}
                    width="90px"
                    height="75px"
                    src="https://safeconnect-beta.s3.us-west-2.amazonaws.com/static-files/safeConnectTransparent.png"
                />
            </div>
        </div>
    );
}


export function removeByValue(array: any, removedArray: any) { 
    for(let i=0; i<=removedArray.length; i++) {
        delete array[removedArray[i]]; 
    } 
    return array;
}
export function renderATCSEHints(field, forms) {
    if( !forms.CSEClassLimit) {
        return '';
    }
    //let atType = forms.atmosphericTestType==='Work Management System Atmospheric Test Record' ;
    let atType = true;
    let cseType = forms.CSEClassLimit==='Class 1'; 
    //let cseType = true;
    let res = ''; 
    switch(field) {
        case 'O2':
            if(atType ) {
                if(cseType) res = '19.5 to 23.5%'; else res = '16% to 19.5% or > 23.5%';
            }
            break;
        case 'LELGW':
            if(atType ) {
                if(cseType) res = '<= 1% of LEL (HC)'; else res = '< 10% of LEL (HC)';
            }
            break;
        case 'LELF':
            if(atType ) {
                if(cseType) res = '0% of LEL'; else res = '0% of LEL';
            }
            break;
        case 'VOC': 
            if(cseType) res = '< 500 ppm'; else res = '< 1000 ppm (IDLH)'; 
            break;
        case 'THC':
            if(atType ) {
                if(cseType) res = '< 500 ppm'; else res = '< 1000 ppm (IDLH)';
            }
            break;
        case 'H2S':
            if(atType ) {
                if(cseType) res = '< 5 PPm'; else res = '< 100 ppm (IDLH)';
            }
            break;
        case 'CO':
            if(atType ) {
                if(cseType) res = '< 25 ppm'; else res = '< 1200 ppm (IDLH)';
            }
            break;
        case 'Benzene':
            if(atType ) {
                if(cseType) res = '< 0.5 ppm'; else res = '< 500 ppm (IDLH)';
            }
            break;
        case 'SO2':
            if(atType ) {
                if(cseType) res = '< 0.5 ppm'; else res = '< 100 ppm (IDLH)';
            }
            break;
    }
    return res; 
}
export function getSWPChecklistHints(type) {
    let res = '';
    switch(type) {
        case 'abrasive blasting': res = 'involves the use of sand or grit for surface preparation including rust and paint removal.'; break;
        case 'breaking containment': res = 'describes the breaking or opening of the containment of energy and substances stored within systems such as hydrocarbons, chemicals, air, steam, fluids and gases. Examples include unbolting flanges and man-ways, removing screwed fittings on systems (pipes, vessels, exchangers, valves, turbines, compressors and pumps), opening enclosures such as pig launchers/receivers and filter housings, the opening of vents or drains, and the removal of orifice plates.'; break;
        case 'confined space': res = 'occurs when the breathing zone of a worker breaks the plane of a confined space opening. '; break;
        case 'critical lift operations': res = 'is a lift considered to be non-routine and includes: lifts above 80% of the crane certified capacity; lifts during wind speed and wave height conditions above crane design operating limits; lifts in proximity to live equipment or power lines with potential for dropped object or impact; lifts of hazardous materials; the use of multiple cranes; lifting through hatches, lifting during SIMOPS activities; personnel transfers using crane not done on a regular basis; use of work basket, Bosun\'s chair or specially designed suspension harness.'; break;
        case 'diving operations': res = 'refers to underwater manned diving activity and related decompression/recompression facilities.'; break;
        case 'excavation ground disturbance': res = 'refers to activities that penetrate the surface such as digging, drilling, removing the surface to form a cavity or depression, and other operations that may present a hazard.'; break;
        case 'high risk electical': res = 'involves activities with the potential (accidental or otherwise) for individuals to come in contact with energized electrical components of 300 volts or greater, or to be exposed to high temperatures or flashes resulting from electrical arcing or electrical fault conditions. Work on any live electrical system is considered higher risk electrical work regardless of voltage.'; break;
        case 'hot work': res = 'refers to any open flame or spark throwing operations and includes: welding cutting, burning, use of open flame, grinding, gouging, brazing, stress relieving at temperatures above 400F (200 C), propane heating, the use of a de-burring tool, and any work activity generating sparks or slag that can be thrown out from the point of work.'; break;
        case 'working at heights': res = 'refers to any open flame or spark throwing operations and includes: welding cutting, burning, use of open flame, grinding, gouging, brazing, stress relieving at temperatures above 400F (200 C), propane heating, the use of a de-burring tool, and any work activity generating sparks or slag that can be thrown out from the point of work.'; break;
    }
    return res;
} 
export function firstLetterCaps(string) {
    //return string.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }); 
}
export function getChecklistReferenceDocument(formType, title, linkKey) { 
    let docTitle = ''; let docLink = '';
    switch(formType) {
        case 'JSA':
            docTitle = title; docLink = `/jsa-reports/${linkKey}`; break;
        case 'checklist':
            docTitle = title; docLink = `/sw-report-detail/${linkKey}`; break;
    }
    if(!docLink) return <></>

    return <div style={{padding: '10px'}}>
        {docTitle}<br/ >
        <span className="file-upload col-sm-2"><span className="images"><div key={'default'}   className="image-wrapper">   
            <a href={`#${docLink}`} title={`Open ${docTitle}`} target="_BLANK" rel="noreferrer"><span><i className="fa fa-file-o" style={{fontSize: '75px'}} aria-hidden="true"></i> </span>  </a> 
        </div></span></span>
    </div>
}
export function getPermanentDeviation(answer: any) {
    let completed = '';
    let statusClass = ''; 
    let approvalStatus: any = '';
    if(answer.productionManagerApprovedDate) {
        if(answer.productionManagerApproval==='0') 
            statusClass = 'danger'; 
        else
            statusClass = 'success'; 
        completed = '5';
    } else if(answer.operationsManagerApprovedDate) {
        if(answer.operationsManagerApproval==='0') 
            statusClass = 'danger'; 
        else
            statusClass = 'default'; 
        completed = '4';
    } else if(answer.safetyManagerApprovedDate) {
        if(answer.safetyManagerApproval==='0') 
            statusClass = 'danger'; 
        else
            statusClass = 'default'; 
        completed = '3';
    } else if(answer.productionSupervisorApprovedDate) {
        statusClass = 'default';  completed = '2';
    } else if(answer.safetySupervisorApprovedDate) {
        statusClass = 'default';  completed = '1';
    } else {
        statusClass = 'default';  completed = '0';
    }
    if(statusClass==='default') {
        approvalStatus = ' - ';
    } else if(statusClass==='success') {
        approvalStatus = 'Approved';
    } else if(statusClass==='danger') {
        approvalStatus = 'Denied';
    }
    return {completed, approvalStatus, statusClass };
}
export function getTemporaryDeviationStatus(answer: any) {
    let completed: any = '';
    let statusClass: any = 'default';
    let total: any = '6';
    let approvalStatus: any = '';
    if(answer.productionManager) {
        completed = answer.globalOperationsManagerApprovedDate?'6':answer.regionalVicePresidentApprovedDate?'5':answer.generalManagerApprovedDate?'4':answer.productionManagerApprovedDate?'3':answer.operationsManagerApprovedDate?'2':answer.safetyManagerApprovedDate?'1':'0';
        if(completed==='6')
            statusClass = 'success';
        else
            statusClass = 'default'; 
    } else {
        completed = answer.globalOperationsManagerApprovedDate?'4':answer.regionalVicePresidentApprovedDate?'3':answer.operationsManagerApprovedDate?'2':answer.safetyManagerApprovedDate?'1':'0';
        if(completed==='4')
            statusClass = 'success';
        else
            statusClass = 'default';
        total = '4'; 
    } 
    if(statusClass==='success') {
        approvalStatus = 'Approved';
    } else if(statusClass==='default') {
        approvalStatus = ' - ';
    }
    return {total, completed, approvalStatus, statusClass}
}
export async function dispatchCommonFilter(urlParams) {
    if(urlParams) { 
        for (const [key, value] of Object.entries(urlParams)) {
            await dispatch(actions.change(`forms.commonFilterForm.[${key}]`, value)) 
        } 
    } 
    return true
}